@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700|Roboto+Condensed:400,700|Roboto+Slab:400,700|Roboto:400,500,700);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@charset "UTF-8";
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  -webkit-appearance: none;
          appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
          appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
          appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  -webkit-user-select: all !important;
          user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
          user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
          user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
img {
    page-break-inside: avoid;
  }
  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }
  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger.is-active:hover {
  opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #000;
}

.hamburger-box {
  width: 25px;
  height: 17px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 25px;
  height: 3px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -7px;
}
.hamburger-inner::after {
  bottom: -7px;
}

/*
 * 3DX
 */
.hamburger--3dx .hamburger-box {
  perspective: 50px;
}
.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(180deg);
}
.hamburger--3dx.is-active .hamburger-inner::before {
  transform: translate3d(0, 7px, 0) rotate(45deg);
}
.hamburger--3dx.is-active .hamburger-inner::after {
  transform: translate3d(0, -7px, 0) rotate(-45deg);
}

/*
 * 3DX Reverse
 */
.hamburger--3dx-r .hamburger-box {
  perspective: 50px;
}
.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(-180deg);
}
.hamburger--3dx-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 7px, 0) rotate(45deg);
}
.hamburger--3dx-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -7px, 0) rotate(-45deg);
}

/*
 * 3DY
 */
.hamburger--3dy .hamburger-box {
  perspective: 50px;
}
.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(-180deg);
}
.hamburger--3dy.is-active .hamburger-inner::before {
  transform: translate3d(0, 7px, 0) rotate(45deg);
}
.hamburger--3dy.is-active .hamburger-inner::after {
  transform: translate3d(0, -7px, 0) rotate(-45deg);
}

/*
 * 3DY Reverse
 */
.hamburger--3dy-r .hamburger-box {
  perspective: 50px;
}
.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg);
}
.hamburger--3dy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 7px, 0) rotate(45deg);
}
.hamburger--3dy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -7px, 0) rotate(-45deg);
}

/*
 * 3DXY
 */
.hamburger--3dxy .hamburger-box {
  perspective: 50px;
}
.hamburger--3dxy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg);
}
.hamburger--3dxy.is-active .hamburger-inner::before {
  transform: translate3d(0, 7px, 0) rotate(45deg);
}
.hamburger--3dxy.is-active .hamburger-inner::after {
  transform: translate3d(0, -7px, 0) rotate(-45deg);
}

/*
 * 3DXY Reverse
 */
.hamburger--3dxy-r .hamburger-box {
  perspective: 50px;
}
.hamburger--3dxy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
}
.hamburger--3dxy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 7px, 0) rotate(45deg);
}
.hamburger--3dxy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -7px, 0) rotate(-45deg);
}

/*
 * Arrow
 */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-5px, 0, 0) rotate(-45deg) scale(0.7, 1);
}
.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-5px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
 * Arrow Right
 */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(5px, 0, 0) rotate(45deg) scale(0.7, 1);
}
.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(5px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
 * Arrow Alt
 */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-5px, -6.25px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-5px, 6.25px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
 * Arrow Alt Right
 */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(5px, -6.25px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(5px, 6.25px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
 * Arrow Turn
 */
.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg);
}
.hamburger--arrowturn.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}
.hamburger--arrowturn.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
 * Arrow Turn Right
 */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg);
}
.hamburger--arrowturn-r.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}
.hamburger--arrowturn-r.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
 * Boring
 */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none;
}
.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg);
}
.hamburger--boring.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}
.hamburger--boring.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

/*
 * Collapse
 */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse .hamburger-inner::after {
  top: -14px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -7px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Collapse Reverse
 */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse-r .hamburger-inner::after {
  top: -14px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.hamburger--collapse-r .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -7px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
.hamburger--collapse-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Elastic
 */
.hamburger--elastic .hamburger-inner {
  top: 1.5px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic .hamburger-inner::before {
  top: 7px;
  transition: opacity 0.125s 0.275s ease;
}
.hamburger--elastic .hamburger-inner::after {
  top: 14px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 7px, 0) rotate(135deg);
  transition-delay: 0.075s;
}
.hamburger--elastic.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}
.hamburger--elastic.is-active .hamburger-inner::after {
  transform: translate3d(0, -14px, 0) rotate(-270deg);
  transition-delay: 0.075s;
}

/*
 * Elastic Reverse
 */
.hamburger--elastic-r .hamburger-inner {
  top: 1.5px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic-r .hamburger-inner::before {
  top: 7px;
  transition: opacity 0.125s 0.275s ease;
}
.hamburger--elastic-r .hamburger-inner::after {
  top: 14px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 7px, 0) rotate(-135deg);
  transition-delay: 0.075s;
}
.hamburger--elastic-r.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}
.hamburger--elastic-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -14px, 0) rotate(270deg);
  transition-delay: 0.075s;
}

/*
 * Emphatic
 */
.hamburger--emphatic {
  overflow: hidden;
}
.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}
.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}
.hamburger--emphatic .hamburger-inner::after {
  top: 7px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}
.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}
.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -50px;
  top: -50px;
  transform: translate3d(50px, 50px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -50px;
  top: -50px;
  transform: translate3d(-50px, 50px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
 * Emphatic Reverse
 */
.hamburger--emphatic-r {
  overflow: hidden;
}
.hamburger--emphatic-r .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}
.hamburger--emphatic-r .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}
.hamburger--emphatic-r .hamburger-inner::after {
  top: 7px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}
.hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}
.hamburger--emphatic-r.is-active .hamburger-inner::before {
  left: -50px;
  top: 50px;
  transform: translate3d(50px, -50px, 0) rotate(-45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.hamburger--emphatic-r.is-active .hamburger-inner::after {
  right: -50px;
  top: 50px;
  transform: translate3d(-50px, -50px, 0) rotate(45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
 * Minus
 */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear;
}
.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear;
}
.hamburger--minus.is-active .hamburger-inner::before {
  top: 0;
}
.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0;
}

/*
 * Slider
 */
.hamburger--slider .hamburger-inner {
  top: 1.5px;
}
.hamburger--slider .hamburger-inner::before {
  top: 7px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}
.hamburger--slider .hamburger-inner::after {
  top: 14px;
}
.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 7px, 0) rotate(45deg);
}
.hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-3.5714285714px, -4px, 0);
  opacity: 0;
}
.hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -14px, 0) rotate(-90deg);
}

/*
 * Slider Reverse
 */
.hamburger--slider-r .hamburger-inner {
  top: 1.5px;
}
.hamburger--slider-r .hamburger-inner::before {
  top: 7px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}
.hamburger--slider-r .hamburger-inner::after {
  top: 14px;
}
.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 7px, 0) rotate(-45deg);
}
.hamburger--slider-r.is-active .hamburger-inner::before {
  transform: rotate(45deg) translate3d(3.5714285714px, -4px, 0);
  opacity: 0;
}
.hamburger--slider-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -14px, 0) rotate(90deg);
}

/*
 * Spin
 */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Spin Reverse
 */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin-r .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin-r .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Spring
 */
.hamburger--spring .hamburger-inner {
  top: 1.5px;
  transition: background-color 0s 0.13s linear;
}
.hamburger--spring .hamburger-inner::before {
  top: 7px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring .hamburger-inner::after {
  top: 14px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important;
}
.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 7px, 0) rotate(45deg);
}
.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 7px, 0) rotate(-45deg);
}

/*
 * Spring Reverse
 */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring-r .hamburger-inner::after {
  top: -14px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear;
}
.hamburger--spring-r .hamburger-inner::before {
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -7px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spring-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear;
}
.hamburger--spring-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Stand
 */
.hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}
.hamburger--stand .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}
.hamburger--stand.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--stand.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Stand Reverse
 */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}
.hamburger--stand-r .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand-r .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}
.hamburger--stand-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--stand-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Squeeze
 */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}
.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Vortex
 */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}
.hamburger--vortex .hamburger-inner::before {
  transition-property: top, opacity;
}
.hamburger--vortex .hamburger-inner::after {
  transition-property: bottom, transform;
}
.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
  transition-delay: 0s;
}
.hamburger--vortex.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}
.hamburger--vortex.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
}

/*
 * Vortex Reverse
 */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}
.hamburger--vortex-r .hamburger-inner::before {
  transition-property: top, opacity;
}
.hamburger--vortex-r .hamburger-inner::after {
  transition-property: bottom, transform;
}
.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
  transition-delay: 0s;
}
.hamburger--vortex-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}
.hamburger--vortex-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

.notification-container--top-center,
.notification-container--top-left,
.notification-container--top-right,
.notification-container--bottom-center,
.notification-container--bottom-left,
.notification-container--bottom-right,
.notification-container--center,
.notification-container--top-full,
.notification-container--bottom-full {
  min-width: 325px;
  position: absolute;
  pointer-events: all;
}

.notification-container--center,
.notification-container--top-center,
.notification-container--bottom-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  left: calc(50% - 175px);
}

.notification-container--center,
.notification-container--top-center,
.notification-container--bottom-center {
  max-width: 350px;
}

.notification-container--center {
  top: 20px;
  height: 100%;
  pointer-events: none;
}

.notification-container--top-full,
.notification-container--bottom-full {
  width: 100%;
  min-width: 100%;
}

.notification-container--bottom-full {
  bottom: 0;
}

.flex-center {
  min-width: 325px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: all;
}

.notification-container--top-center {
  top: 20px;
}

.notification-container--bottom-center {
  bottom: 20px;
}

.notification-container--top-left {
  left: 20px;
  top: 20px;
}

.notification-container--top-right {
  right: 20px;
  top: 20px;
}

.notification-container--bottom-left {
  left: 20px;
  bottom: 20px;
}

.notification-container--bottom-right {
  bottom: 20px;
  right: 20px;
}

.notification-container--mobile-top,
.notification-container--mobile-bottom {
  pointer-events: all;
  position: absolute;
}

.notification-container--mobile-top {
  right: 20px;
  left: 20px;
  top: 20px;
}

.notification-container--mobile-bottom {
  right: 20px;
  left: 20px;
  bottom: 20px;
  margin-bottom: -15px;
}

.notification__item--default {
  background-color: #007bff;
  border-left: 8px solid #0562c7;
}
.notification__item--default .notification__timer {
  background-color: #007bff;
}
.notification__item--default .notification__timer-filler {
  background-color: #fff;
}
.notification__item--default .notification__close {
  background-color: #007bff;
}

.notification__item--success {
  background-color: #28a745;
  border-left: 8px solid #1f8838;
}
.notification__item--success .notification__timer {
  background-color: #28a745;
}
.notification__item--success .notification__timer-filler {
  background-color: #fff;
}
.notification__item--success .notification__close {
  background-color: #28a745;
}

.notification__item--danger {
  background-color: #dc3545;
  border-left: 8px solid #bd1120;
}
.notification__item--danger .notification__timer {
  background-color: #dc3545;
}
.notification__item--danger .notification__timer-filler {
  background-color: #fff;
}
.notification__item--danger .notification__close {
  background-color: #dc3545;
}

.notification__item--info {
  background-color: #17a2b8;
  border-left: 8px solid #138b9e;
}
.notification__item--info .notification__timer {
  background-color: #17a2b8;
}
.notification__item--info .notification__timer-filler {
  background-color: #fff;
}
.notification__item--info .notification__close {
  background-color: #17a2b8;
}

.notification__item--warning {
  background-color: #ffc107;
  border-left: 8px solid #ce9c09;
}
.notification__item--warning .notification__timer {
  background-color: #eab000;
}
.notification__item--warning .notification__timer-filler {
  background-color: #fff;
}
.notification__item--warning .notification__close {
  background-color: #ffc107;
}

.notification__item--awesome {
  background-color: #685dc3;
  border-left: 8px solid #4c3fb1;
}
.notification__item--awesome .notification__timer {
  background-color: #685dc3;
}
.notification__item--awesome .notification__timer-filler {
  background-color: #fff;
}
.notification__item--awesome .notification__close {
  background-color: #685dc3;
}

@keyframes timer {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
.notifications-component {
  position: fixed;
  z-index: 9000;
  pointer-events: none;
  width: 100%;
  height: 100%;
}

.notification__item {
  display: flex;
  position: relative;
  border-radius: 3px;
  margin-bottom: 15px;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.notification-container--top-full .notification__item,
.notification-container--bottom-full .notification__item {
  margin-bottom: 0;
  border-radius: 0;
}

.notification__timer {
  width: 100%;
  height: 3px;
  margin-top: 10px;
  border-radius: 5px;
}

.notification__timer-filler {
  height: 3px;
  border-radius: 5px;
}

.notification__title {
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.notification__message {
  color: #fff;
  max-width: calc(100% - 15px);
  font-size: 14px;
  line-height: 150%;
  word-wrap: break-word;
  margin-bottom: 0;
  margin-top: 0;
}

.notification__content {
  padding: 8px 15px;
  display: inline-block;
  width: 100%;
}

.notification__close {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 10px;
}
.notification__close::after {
  content: "×";
  position: absolute;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 12px;
  left: 50%;
  top: 50%;
}

.notification-container--mobile-top .notification__item,
.notification-container--mobile-bottom .notification__item,
.notification-container--mobile-top .notification,
.notification-container--mobile-bottom .notification {
  max-width: 100%;
  width: 100%;
}

.notification-container--top-right .notification,
.notification-container--bottom-right .notification {
  margin-left: auto;
}

.notification-container--top-left .notification,
.notification-container--bottom-left .notification {
  margin-right: auto;
}

.notification-container--mobile-top .notification,
.notification-container--mobile-bottom .notification {
  margin-left: auto;
  margin-right: auto;
}

.react-responsive-modal-overlay {
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1000;
  padding: 1.2rem;
}

.react-responsive-modal-modal {
  max-width: 800px;
  position: relative;
  padding: 1.2rem;
  background: #ffffff;
  background-clip: padding-box;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  margin: 0 auto;
}

.react-responsive-modal-modalCenter {
  margin: auto;
}

.react-responsive-modal-closeButton {
  position: absolute;
  top: 14px;
  right: 14px;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;
}

@keyframes react-responsive-modal-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes react-responsive-modal-fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.siteContainer > header {
  box-shadow: 0 0.22rem 0.2rem rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
  z-index: 999;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
@media (max-width: 767.98px) {
  .siteContainer > header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
}
.siteContainer > header .row {
  height: 55px;
}
@media (min-width: 992px) {
  .siteContainer > header .row {
    position: relative;
  }
}
.siteContainer > header .hamburger {
  padding-top: 20px;
  padding-left: 0;
}
.siteContainer > header .logo {
  display: flex;
  flex: 0 1;
  align-self: center;
  justify-content: center;
  display: block;
  text-decoration: none;
  text-align: left;
}
@media (max-width: 1199.98px) {
  .siteContainer > header .logo {
    flex: 1 1;
    text-align: center;
  }
}
@media screen and (max-width: 450px) {
  .siteContainer > header .logo {
    width: 200px;
  }
  .siteContainer > header .logo img {
    width: 200px;
  }
}
@media screen and (max-width: 349px) {
  .siteContainer > header .logo {
    width: 175px;
  }
  .siteContainer > header .logo img {
    width: 175px;
  }
}
.siteContainer > header .menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 1;
  min-height: 4rem;
  margin: 0;
  list-style: none;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.siteContainer > header .menu li {
  float: left;
  list-style: none;
}
.siteContainer > header .menu li a {
  font-weight: 500;
  line-height: 27.5px;
  padding: 0 20px;
  text-decoration: none;
  color: #4E4E4E;
}
.siteContainer > header .menu li a:hover, .siteContainer > header .menu li a:visited {
  color: #9a9a9a;
}
.siteContainer > header .menu li.separator {
  border-left: 1px solid;
  width: 1px;
  height: 20px;
}
.siteContainer > header .menu li.loginBtn a {
  border: 1px solid #000;
  font-weight: bold;
  line-height: 21px;
  width: 95px;
  border-radius: 3px;
}
.siteContainer > header .searchToggle {
  flex: 0 1;
  outline: none;
  padding: 10px 10px;
  display: inline-block;
  cursor: pointer;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  vertical-align: text-top;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 21px;
}
@media (max-width: 450px) {
  .siteContainer > header .searchToggle {
    padding-right: 5px;
  }
}
.siteContainer > header .searchToggle svg {
  margin-right: 2px;
}
.siteContainer > header .searchToggle:hover {
  color: #9a9a9a;
}
.siteContainer > header .searchToggle.is-open {
  color: #9a9a9a !important;
}
.siteContainer > header .searchbar {
  padding: 0;
  position: absolute;
  top: 55px;
  right: 0;
  background: #f3f3f3;
  width: 480px;
  box-shadow: 0 3px 4px 0px rgba(0, 0, 0, 0.12);
  text-align: right;
  display: none;
  z-index: 999;
}
@media (max-width: 991.98px) {
  .siteContainer > header .searchbar {
    text-align: center;
    width: 100%;
  }
}
.siteContainer > header .searchbar form {
  padding: 20px 20px;
}
@media (max-width: 991.98px) {
  .siteContainer > header .searchbar form {
    padding-left: 5%;
    padding-right: 5%;
  }
}
.siteContainer > header .searchbar form .searchOptions {
  padding: 15px 5px;
}
.siteContainer > header .searchbar form .input-group {
  background: white;
  border: 0.5px solid #9a9a9a;
  border-radius: 3px;
  box-shadow: inset 0 1px 0 1px #D7D7D7;
}
.siteContainer > header .searchbar form .input-group input {
  padding-left: 15px;
  background: none;
  border: none;
  line-height: 24px;
}
@media (max-width: 991.98px) {
  .siteContainer > header .searchbar form .input-group input {
    width: 85%;
  }
}
.siteContainer > header .searchbar form button {
  background: none;
  border: none;
  line-height: 19px;
  color: #4E4E4E;
}
.siteContainer > header .headMsgContainer {
  background-color: #fff3cd;
  border-color: #ffeeba;
  border-style: solid;
  border-width: 1px 0 1px 0;
}
.siteContainer > header .headMsgContainer .headMsgWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.siteContainer > header .headMsgContainer .headMsgWrapper > p {
  color: #856404;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0;
}
.siteContainer > header .contentNotifications .notificationsIcon {
  width: 24px;
  height: 24px;
}
.siteContainer > header .contentNotifications .notificationsBadge {
  top: -10px;
  left: -10px;
}
.siteContainer > header .notificationMobile {
  display: flex;
  align-items: center;
}
.siteContainer > header .notificationMobile button {
  font-size: 0.95rem;
  display: flex;
  flex-wrap: nowrap;
}
@media (max-width: 450px) {
  .siteContainer > header .notificationMobile button {
    padding: 0.375rem 0.5rem;
  }
}
.siteContainer > header .notificationMobile button svg {
  width: 1em;
  height: 1em;
  font-size: 1.5em;
}
.siteContainer > header .notificationMobile button span {
  top: -5px;
  left: -10px;
}
.siteContainer > header .skip-link {
  background-color: #0c3f57;
  color: #fff;
  font-weight: 700;
  left: 50%;
  padding: 4px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;
  border-radius: 0;
  border: none;
  margin-top: -30px;
}
.siteContainer > header .skip-link:focus {
  transform: translateY(0%);
}

.myAccountButton {
  background-color: #1A6690;
  color: white;
  border: 2px solid #07547e;
  border-radius: 3px;
  font-weight: 800;
  margin-right: 10px;
}
.myAccountButton:hover {
  cursor: pointer;
}
.myAccountButton.focus {
  color: white;
  background: #004A74;
}
.myAccountButton.active {
  border-color: #9B9B9B;
  background: #004A74;
}
.myAccountButton > a {
  color: white;
  position: relative;
}
.myAccountButton > a::before {
  right: 14px;
  transform: rotate(45deg);
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -1px;
  height: 2px;
  width: 9px;
  background: #ffffff;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: width 0.3s, transform 0.3s;
}
.myAccountButton > a::after {
  right: 9px;
  transform: rotate(-45deg);
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -1px;
  height: 2px;
  width: 9px;
  background: #ffffff;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: width 0.3s, transform 0.3s;
}
.myAccountButton > a.active::before {
  right: 9px;
  transform: rotate(135deg);
  width: 14px;
}
.myAccountButton > a.active::after {
  right: 9px;
  transform: rotate(-135deg);
  width: 14px;
}
.myAccountButton .myAccountDropdown {
  display: block;
  position: absolute;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  background-color: white;
  max-width: 270px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.12);
  margin-left: -1px;
  margin-top: 3px;
  z-index: 999;
}
.myAccountButton .myAccountDropdown li {
  display: block;
  float: none;
  width: 100%;
}
.myAccountButton .myAccountDropdown li.sub {
  padding-left: 10px;
}
.myAccountButton .myAccountDropdown li a {
  width: 100%;
  display: block;
  text-decoration: none;
  color: #4E4E4E;
  border: none;
}
.myAccountButton .myAccountDropdown li a:hover {
  color: #9a9a9a;
}
.myAccountButton .myAccountDropdown li a:focus {
  color: #1A6690;
}

.menuDDButton:hover {
  cursor: pointer;
}
.menuDDButton > a {
  color: #4E4E4E;
  position: relative;
  padding-right: 20px !important;
  display: block;
}
.menuDDButton > a::before {
  right: 5px;
  transform: rotate(45deg);
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -1px;
  height: 2px;
  width: 9px;
  background: #4E4E4E;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: width 0.3s, transform 0.3s;
}
.menuDDButton > a::after {
  right: 0;
  transform: rotate(-45deg);
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -1px;
  height: 2px;
  width: 9px;
  background: #4E4E4E;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: width 0.3s, transform 0.3s;
}
.menuDDButton > a:hover {
  color: inherit !important;
}
.menuDDButton .menuDropdown {
  display: block;
  position: absolute;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 20px;
  background-color: white;
  max-width: 230px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.12);
  margin-left: 10px;
  margin-top: 3px;
  z-index: 999;
}
.menuDDButton .menuDropdown li {
  display: block;
  float: none;
  width: 100%;
  margin-top: 10px;
}
.menuDDButton .menuDropdown li.sub {
  padding-left: 10px;
}
.menuDDButton .menuDropdown li a {
  width: 100%;
  display: block;
  text-decoration: none;
  color: #4E4E4E;
  border: none;
}
.menuDDButton .menuDropdown li a:hover {
  color: #9a9a9a;
}
.menuDDButton .menuDropdown li a:focus {
  color: #1A6690;
}

.siteContainer > section.offgridSidebar {
  overflow: scroll;
  background-color: white;
  position: fixed;
  left: -315px;
  height: 100%;
  color: white;
  z-index: 999;
  padding-top: 2rem;
  transition: all 0.15s ease;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0.3rem 0 0.2rem rgba(0, 0, 0, 0.1);
}
.siteContainer > section.offgridSidebar.slideActive {
  left: 0;
}
.siteContainer > section.offgridSidebar ul {
  padding: 2rem;
}
.siteContainer > section.offgridSidebar ul.partnerNav a {
  color: #4E4E4E;
}
.siteContainer > section.offgridSidebar ul li {
  list-style: none;
}
.siteContainer > section.offgridSidebar ul li a {
  padding: 8px 0;
  text-decoration: none;
  color: #4E4E4E;
  font-size: 0.95rem;
  font-weight: 500;
  display: block;
  transition: 0.3s;
}
.siteContainer > section.offgridSidebar ul li a:hover {
  color: #9a9a9a;
}
.siteContainer > section.offgridSidebar ul li a:focus {
  color: #1A6690;
}
.siteContainer > section.offgridSidebar ul li.sub {
  padding-left: 10px;
}
.siteContainer > section.offgridSidebar ul li.sub a {
  font-weight: 400;
  color: #626262;
}
.siteContainer > section.offgridSidebar ul li.sub a:hover {
  color: #9a9a9a;
}
.siteContainer > section.offgridSidebar ul li.sub a:focus {
  color: #1A6690;
}
.siteContainer > section.offgridSidebar ul li.logoutBtn {
  cursor: pointer;
}
.siteContainer > section.offgridSidebar ul li.logoutBtn a {
  margin-top: 1rem;
  text-align: center;
  background-color: #000;
  color: white;
  border: 2px solid #000;
  border-radius: 3px;
  padding: 5px 20px;
  font-weight: 800;
  max-height: 40px;
  -webkit-font-smoothing: antialiased;
}
.siteContainer > section.offgridSidebar ul li.logoutBtn a:focus {
  background: #000;
  color: white;
}
.siteContainer > section.offgridSidebar ul li.loginBtn {
  margin-top: 10px;
}
.siteContainer > section.offgridSidebar ul li.loginBtn a {
  text-align: center;
  color: #000;
  border: 2px solid #000;
  border-radius: 3px;
  font-weight: 800;
  margin-bottom: 20px;
  max-height: 40px;
}
.siteContainer > section.offgridSidebar ul li.loginBtn a:hover {
  border-color: #000;
  background-color: #000;
  color: white;
}
.siteContainer > section.offgridSidebar ul li.loginBtn a:focus {
  background: #000;
  color: white;
}
.siteContainer > section.offgridSidebar ul li.separator {
  border-top: 1px solid gray;
  margin-top: 35px;
  margin-bottom: 25px;
}

.siteContainer > section.headAdContainer {
  background-color: white;
  height: 100%;
  max-height: 100px;
  z-index: -1;
}
.siteContainer > section.headAdContainer .headAdWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.backToTop {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
}
.backToTop-btn {
  color: #666;
}
.backToTop-btn:hover {
  background-color: #9a9a9a;
  color: white;
}
.backToTop-btn:focus {
  outline: 0;
}

.tour-class svg {
  width: 15px;
}

#contentNotificationsDropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 0;
  background-color: white;
  max-width: 470px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-left: -420px;
  margin-top: 5px;
  z-index: 999;
  width: 470px;
  max-height: 450px;
  overflow: hidden;
  overflow-y: scroll;
}
@media (max-width: 1199.98px) {
  #contentNotificationsDropdown {
    margin-left: -475px;
    margin-top: 20px;
  }
}
@media (max-width: 500px) {
  #contentNotificationsDropdown {
    width: auto;
    margin-left: 0;
    left: 10px;
    right: 10px;
  }
}
@media (max-width: 470px) {
  #contentNotificationsDropdown {
    left: 5px;
    right: 5px;
  }
}
#contentNotificationsDropdown .bold {
  font-weight: 500;
}
#contentNotificationsDropdown > header {
  height: 56px;
  min-height: 56px;
  flex: 0 1;
  background-color: white;
  display: flex;
  justify-self: center;
  padding-left: 15px;
}
#contentNotificationsDropdown > header > div {
  display: flex;
  padding-left: 5px;
}
#contentNotificationsDropdown > header > div > div {
  display: flex;
  align-self: center;
  margin-right: 4px;
}
#contentNotificationsDropdown > main {
  background-color: #F5F5F5;
  flex: 1 1;
}
#contentNotificationsDropdown > main .notificationEntry {
  display: flex;
  flex-direction: row;
  box-shadow: 0 8px 20px rgba(15, 51, 71, 0.05), 0 2px 5px rgba(15, 51, 71, 0.05), 0 0 1px rgba(15, 51, 71, 0.15);
  border-radius: 8px;
  max-width: 445px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  overflow: hidden;
  align-self: center;
}
#contentNotificationsDropdown > main .notificationEntry > header {
  width: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#contentNotificationsDropdown > main .notificationEntry > header > svg {
  width: 24px;
}
#contentNotificationsDropdown > main .notificationEntry > main {
  background-color: white;
  flex: 1 1;
  padding: 0 15px;
  color: #3A424C;
  font-size: 14px;
  font-weight: 500;
  min-height: 84px;
}
#contentNotificationsDropdown > main .notificationEntry > main .title {
  padding-top: 8px;
}
#contentNotificationsDropdown > main .notificationEntry > main .title a {
  padding-left: 0;
  font-size: 1em;
}
#contentNotificationsDropdown > main .notificationEntry > main .pubName {
  font-weight: 700;
}
#contentNotificationsDropdown > main .notificationEntry > main .actions a {
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
  padding-left: 0;
}
#contentNotificationsDropdown > main .notificationEntry > main .actions .publicationName {
  vertical-align: middle;
}
#contentNotificationsDropdown > main .notificationEntry > main .actions button.secondary {
  color: #888888;
}
#contentNotificationsDropdown > main .notificationEntry.NotificationEntryRenew > header {
  background-color: #EEA445;
}
#contentNotificationsDropdown > main .notificationEntry.NotificationEntryWebinar > header {
  background-color: inherit;
}
#contentNotificationsDropdown > main .notificationEntry.NotificationEntryUpdate, #contentNotificationsDropdown > main .notificationEntry.NotificationEntryReport, #contentNotificationsDropdown > main .notificationEntry.NotificationEntryIssue, #contentNotificationsDropdown > main .notificationEntry.PromotionalNotification {
  min-height: 65px;
  max-width: 460px;
  border-radius: 0;
  margin-top: 0;
  box-shadow: none;
  border-bottom: 2px solid #EDEDED;
}
#contentNotificationsDropdown > main .notificationEntry.NotificationEntryUpdate > header, #contentNotificationsDropdown > main .notificationEntry.NotificationEntryReport > header, #contentNotificationsDropdown > main .notificationEntry.NotificationEntryIssue > header, #contentNotificationsDropdown > main .notificationEntry.PromotionalNotification > header {
  min-width: 66px;
}
#contentNotificationsDropdown > main .notificationEntry.NotificationEntryUpdate > header .circle, #contentNotificationsDropdown > main .notificationEntry.NotificationEntryReport > header .circle, #contentNotificationsDropdown > main .notificationEntry.NotificationEntryIssue > header .circle, #contentNotificationsDropdown > main .notificationEntry.PromotionalNotification > header .circle {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #FECF60;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
#contentNotificationsDropdown > main .notificationEntry.NotificationEntryUpdate > header .circle svg, #contentNotificationsDropdown > main .notificationEntry.NotificationEntryReport > header .circle svg, #contentNotificationsDropdown > main .notificationEntry.NotificationEntryIssue > header .circle svg, #contentNotificationsDropdown > main .notificationEntry.PromotionalNotification > header .circle svg {
  stroke: white;
}
#contentNotificationsDropdown > main .notificationEntry.NotificationEntryUpdate > main, #contentNotificationsDropdown > main .notificationEntry.NotificationEntryReport > main, #contentNotificationsDropdown > main .notificationEntry.NotificationEntryIssue > main, #contentNotificationsDropdown > main .notificationEntry.PromotionalNotification > main {
  background-color: inherit;
  padding-left: 0;
  width: 100%;
}
#contentNotificationsDropdown > main .notificationEntry.NotificationEntryUpdate > main .title, #contentNotificationsDropdown > main .notificationEntry.NotificationEntryReport > main .title, #contentNotificationsDropdown > main .notificationEntry.NotificationEntryIssue > main .title, #contentNotificationsDropdown > main .notificationEntry.PromotionalNotification > main .title {
  padding-top: 20px;
  padding-bottom: 0;
}
#contentNotificationsDropdown > main .notificationEntry.NotificationEntryUpdate > main .title a, #contentNotificationsDropdown > main .notificationEntry.NotificationEntryReport > main .title a, #contentNotificationsDropdown > main .notificationEntry.NotificationEntryIssue > main .title a, #contentNotificationsDropdown > main .notificationEntry.PromotionalNotification > main .title a {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 80%;
}
#contentNotificationsDropdown > main .notificationEntry.NotificationEntryUpdate > main .actions, #contentNotificationsDropdown > main .notificationEntry.NotificationEntryReport > main .actions, #contentNotificationsDropdown > main .notificationEntry.NotificationEntryIssue > main .actions, #contentNotificationsDropdown > main .notificationEntry.PromotionalNotification > main .actions {
  color: #888888;
  margin-top: -7px;
}
#contentNotificationsDropdown > main .notificationEntry.NotificationEntryUpdate > main .actions a, #contentNotificationsDropdown > main .notificationEntry.NotificationEntryReport > main .actions a, #contentNotificationsDropdown > main .notificationEntry.NotificationEntryIssue > main .actions a, #contentNotificationsDropdown > main .notificationEntry.PromotionalNotification > main .actions a {
  color: #888888;
}
#contentNotificationsDropdown > main .notificationEntry.NotificationEntryUpdate > main .actions .divider, #contentNotificationsDropdown > main .notificationEntry.NotificationEntryReport > main .actions .divider, #contentNotificationsDropdown > main .notificationEntry.NotificationEntryIssue > main .actions .divider, #contentNotificationsDropdown > main .notificationEntry.PromotionalNotification > main .actions .divider {
  padding-left: 8px;
  padding-right: 8px;
}
#contentNotificationsDropdown > main .notificationEntry.NotificationEntryUpdate.unread > header, #contentNotificationsDropdown > main .notificationEntry.NotificationEntryUpdate.unread > main, #contentNotificationsDropdown > main .notificationEntry.NotificationEntryReport.unread > header, #contentNotificationsDropdown > main .notificationEntry.NotificationEntryReport.unread > main, #contentNotificationsDropdown > main .notificationEntry.NotificationEntryIssue.unread > header, #contentNotificationsDropdown > main .notificationEntry.NotificationEntryIssue.unread > main, #contentNotificationsDropdown > main .notificationEntry.PromotionalNotification.unread > header, #contentNotificationsDropdown > main .notificationEntry.PromotionalNotification.unread > main {
  background-color: white;
}
#contentNotificationsDropdown > main .notificationEntry.PromotionalNotification > main .content {
  color: #888888;
  max-width: 350px;
}
#contentNotificationsDropdown > main .notificationEntry.PromotionalNotification > main .actions {
  color: #3A424C;
  padding-bottom: 20px;
  padding-top: 10px;
}
#contentNotificationsDropdown > main .notificationEntry.PromotionalNotification > main .actions a {
  color: #3A424C;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 80%;
}
#contentNotificationsDropdown > main .timeline {
  margin-top: 5px;
}
#contentNotificationsDropdown > main .timeline header h5 {
  font-size: 12px;
  text-transform: uppercase;
  margin-left: 10px;
}
#contentNotificationsDropdown > footer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  justify-self: center;
  align-self: center;
  width: 100%;
}
#contentNotificationsDropdown > footer > div {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.siteContainer > footer {
  width: 100%;
  border-top: 1px solid #dfdfdf;
  margin-top: 40px;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
@media screen and (max-width: 767px) {
  .siteContainer > footer a, .siteContainer > footer p, .siteContainer > footer ul, .siteContainer > footer li {
    text-align: center;
  }
  .siteContainer > footer span {
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .siteContainer > footer a {
    text-align: center;
  }
}
.siteContainer > footer .footer-top {
  padding: 30px 0;
  width: 100%;
}
.siteContainer > footer .footer-top ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
@media screen and (max-width: 767px) {
  .siteContainer > footer .footer-top ul li {
    text-align: center;
    padding: 7px;
  }
}
@media screen and (max-width: 767px) {
  .siteContainer > footer .footer-top span {
    text-align: center;
  }
}
.siteContainer > footer .footer-top input {
  border: 1px solid #666666;
  box-sizing: border-box;
  padding: 10px;
}
.siteContainer > footer .footer-top input[type=text] {
  height: 40px;
  color: #666666;
  outline: none;
  text-align: left;
  width: 68%;
  padding: 10px;
  border: 0.5px solid #9A9A9A;
  border-radius: 3px 0 0 3px;
  background-color: #FFFFFF;
}
@media screen and (max-width: 1199px) {
  .siteContainer > footer .footer-top input[type=text] {
    width: 58%;
  }
}
.siteContainer > footer .footer-top input[type=submit] {
  height: 40px;
  font-weight: 500;
  background: #EEA445;
  border: 0.5px solid #E49025;
  border-radius: 0 3px 3px 0;
  padding: 8px 19px;
}
.siteContainer > footer .footer-top input[type=submit]:hover, .siteContainer > footer .footer-top input[type=submit]:active {
  background: #E49025;
  box-shadow: inset 0 -2px 0 0 #DE810C;
}
.siteContainer > footer .footer-top input[type=submit]:focus {
  background: #DE810C;
}
.siteContainer > footer .footer-top .siteLogo {
  max-width: 220px;
}
@media screen and (max-width: 767px) {
  .siteContainer > footer .footer-top .siteLogo {
    max-width: 85%;
  }
}
@media screen and (max-width: 991px) {
  .siteContainer > footer .footer-top .footer-top-contact {
    text-align: center;
  }
}
@media screen and (min-width: 992px) {
  .siteContainer > footer .footer-top p span {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .siteContainer > footer .footer-top p span {
    display: block;
  }
}
.siteContainer > footer .footer-mid {
  padding-top: 20px;
  padding-bottom: 20px;
}
.siteContainer > footer .footer-mid div.items {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
}
.siteContainer > footer .footer-mid div.items div {
  padding-right: 20px;
  border-right: 1px solid #666666;
}
.siteContainer > footer .footer-mid div.items div:not(:first-child) {
  padding-left: 20px;
}
.siteContainer > footer .footer-mid div.items div:last-child {
  border-right: none;
}
@media screen and (max-width: 990px) {
  .siteContainer > footer .footer-mid div.items div {
    margin-bottom: 10px;
  }
  .siteContainer > footer .footer-mid div.items div:first-child {
    padding-left: 20px;
  }
}
.siteContainer > footer .footer-bottom {
  padding-bottom: 21px;
  text-align: center;
}
.siteContainer > footer .footer-bottom ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.siteContainer > footer .footer-bottom .affiliates-desktop {
  align-items: center;
  justify-content: space-between;
  padding-top: 25px;
  padding-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .siteContainer > footer .footer-bottom .affiliates-desktop {
    display: none;
  }
}
.siteContainer > footer .footer-bottom .affiliates-desktop img {
  max-height: 42px;
}
.siteContainer > footer .footer-bottom .affiliates-mobile {
  margin-bottom: 20px;
  margin-top: 10px;
  text-align: center;
  display: none;
}
@media screen and (max-width: 767px) {
  .siteContainer > footer .footer-bottom .affiliates-mobile {
    display: inherit;
  }
}
.siteContainer > footer .footer-bottom .affiliates-mobile ul li {
  display: block;
  margin-right: 0;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .siteContainer > footer .footer-bottom .affiliates-mobile ul li {
    text-align: center;
    padding: 0.3rem;
  }
}
.siteContainer > footer .footer-bottom .copyright {
  text-align: left;
}
@media screen and (max-width: 767px) {
  .siteContainer > footer .footer-bottom .copyright {
    text-align: center;
    font-size: 0.75rem;
    padding-top: 1rem;
  }
}
@media (max-width: 767.98px) {
  .siteContainer > footer .footer-bottom p, .siteContainer > footer .footer-bottom a, .siteContainer > footer .footer-bottom span, .siteContainer > footer .footer-bottom h4 {
    color: inherit;
  }
}
.siteContainer > footer .img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
  vertical-align: center;
}

.portfolioWrapper header {
  background-color: #eee;
  text-align: center;
  margin: 8px 0 0 0;
  padding: 10px 0;
  font-size: 0.8em;
  line-height: 15px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-style: italic;
}
.portfolioWrapper table.full {
  border-collapse: collapse;
  border: 0 solid #e4e4e4;
  width: 100%;
  font-size: 12px;
  color: #727272;
}
.portfolioWrapper table.full thead tr {
  background-color: #CACACA;
}
.portfolioWrapper table.full thead th {
  padding-left: 10px;
  padding-top: 0;
  vertical-align: bottom;
  text-align: left;
  font-size: 11px;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  white-space: nowrap;
  line-height: 20px;
  text-transform: capitalize;
}
.portfolioWrapper table.full tbody tr {
  border-bottom: 1px solid #D6D6D6;
}
.portfolioWrapper table.full tbody tr td {
  padding-left: 10px;
  padding-top: 0;
  vertical-align: bottom;
  text-align: left;
  font-size: 11px;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  white-space: nowrap;
  line-height: 30px;
}
.portfolioWrapper table.compact {
  border: 1px solid #cccccc;
  width: 100%;
  font-size: 12px;
  color: #727272;
  margin-top: 10px;
}
.portfolioWrapper table.compact:hover {
  background-color: #fafafa;
}
.portfolioWrapper table.compact tbody tr {
  margin: 10px auto;
  border-bottom: 1px solid #e4e4e4;
}
.portfolioWrapper table.compact tbody tr td {
  padding-left: 10px;
  padding-top: 0;
  vertical-align: bottom;
  text-align: left;
  font-size: 11px;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  white-space: nowrap;
  line-height: 30px;
  min-height: 30px;
}

.newArticleIndicator {
  width: -webkit-max-content;
  width: max-content;
  padding: 4px 6px;
  border-radius: 3px;
  font-size: 12px;
  line-height: 12px;
  background-color: rgba(103, 189, 82, 0.9);
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}

.r-modal-product-overlay {
  background-color: rgba(255, 255, 255, 0.75);
}

.r-modal-product-modal {
  margin-top: 60px;
  border-radius: 8px;
  border: 1px solid lightgray;
  padding: 40px 80px;
  max-height: 70vh;
  overflow-y: auto;
}
@media (max-width: 767.98px) {
  .r-modal-product-modal {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.r-modal-product-modal h2 {
  color: #1C6240;
  font-size: 25px;
  line-height: 1.15em;
  font-weight: 700;
  text-shadow: none;
  margin: 0.85em 0;
  text-align: center;
  padding: 8px 0;
  font-family: "Roboto", sans-serif;
}
.r-modal-product-modal h4 {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
}
.r-modal-product-modal p {
  font-size: 17px;
  line-height: 27.5px;
  font-weight: 400;
  text-shadow: none;
  margin-bottom: 21px;
  font-family: "Roboto", sans-serif;
}
.r-modal-product-modal video-js {
  width: 100%;
  min-height: 381px;
}
@media (max-width: 767.98px) {
  .r-modal-product-modal video-js {
    min-height: 293px;
  }
}
@media (max-width: 575.98px) {
  .r-modal-product-modal video-js {
    min-height: 193px;
  }
}
.r-modal-product-modal .author-image {
  text-align: center;
}

.r-modal-product-close {
  position: fixed;
  top: 100px;
  right: auto;
  margin-left: 675px;
}
@media (max-width: 836px) {
  .r-modal-product-close {
    top: 100px;
    right: 5%;
    margin-left: 0;
  }
}
.r-modal-product-close svg {
  fill: lightgray;
}
.r-modal-product-close svg:hover {
  fill: darkgrey;
  cursor: pointer;
}

.r-modal-account-overlay {
  background-color: rgba(255, 255, 255, 0.75);
}

.r-modal-account-modal {
  margin-top: 60px;
  border-radius: 8px;
  border: 1px solid lightgray;
  padding: 40px 80px;
  max-height: 70vh;
  overflow-y: auto;
  width: 600px;
}
@media (max-width: 767.98px) {
  .r-modal-account-modal {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.r-modal-account-modal h2 {
  color: #1C6240;
  font-size: 25px;
  line-height: 1.15em;
  font-weight: 700;
  text-shadow: none;
  margin: 0.85em 0;
  text-align: center;
  padding: 8px 0;
  font-family: "Roboto", sans-serif;
}
.r-modal-account-modal h4 {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
}
.r-modal-account-modal p {
  font-size: 17px;
  line-height: 27.5px;
  font-weight: 400;
  text-shadow: none;
  margin-bottom: 21px;
  font-family: "Roboto", sans-serif;
}
.r-modal-account-modal footer {
  margin-top: 20px;
  min-width: 300px;
  text-align: right;
}
.r-modal-account-modal footer button {
  margin-left: 20px;
}

.r-modal-account-close {
  position: fixed;
  top: 100px;
  right: auto;
  margin-left: 480px;
}
@media (max-width: 836px) {
  .r-modal-account-close {
    top: 100px;
    right: 5%;
    margin-left: 0;
  }
}
.r-modal-account-close svg {
  fill: lightgray;
}
.r-modal-account-close svg:hover {
  fill: darkgrey;
  cursor: pointer;
}

table.new tr:nth-child(odd) td {
  background-color: #E1EAF3;
  border-bottom: solid #FFF 1px;
  border-left: solid #FFF 1px;
  border-right: solid #FFF 1px;
  border-top: solid #FFF 1px;
}
table.new tr td:first-child p {
  text-align: left;
}
table.new tr:first-child td {
  background-color: #005695;
}
table.new tr:first-child td > .Body-Text > strong {
  color: #ffffff;
}
table.new tr:first-child td p.Body-Text {
  text-align: center;
  color: #ffffff;
  font-size: 14px;
}
table.new-medium {
  line-height: 1.2;
  margin-bottom: 35px;
  margin-top: 35px;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  border-spacing: 0;
  border-collapse: collapse;
}

.course-container {
  max-width: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
.course-container .row {
  margin: 0;
}
.course-container .col-3, .course-container .col-4, .course-container .col-9 {
  padding: 0;
}
.course-container p {
  margin: 0;
  color: #3A424C;
}
@media (max-width: 991.98px) {
  .course-container > .container {
    padding: 0;
  }
}
.course-container .course-header {
  box-shadow: 0 4px 10px rgba(15, 51, 71, 0.05), 0 2px 3px rgba(15, 51, 71, 0.05), 0 0 1px rgba(15, 51, 71, 0.15);
  margin: 0;
  color: #3A424C;
  min-height: 4.55rem;
  align-items: center;
  padding: 1.42rem 0;
  z-index: 10;
}
@media (max-width: 991.98px) {
  .course-container .course-header {
    padding-bottom: 10px;
  }
}
.course-container .course-header .my-courses {
  padding-left: 1.7rem;
}
.course-container .course-header .my-courses h5 {
  margin: 0;
  color: #3A424C;
  font-size: 1.28rem;
}
.course-container .course-header .course-name {
  padding-left: 5.89rem;
}
.course-container .course-header .course-name h5 {
  margin: 0;
  color: #3A424C;
  font-size: 1.28rem;
}
.course-container .course-header .course-name .site-name a {
  color: #4B7A60;
}
.course-container .course-header .course-name .header-icon {
  padding: 0 0.71rem;
  color: #666360;
}
.course-container .course-selector-utility {
  width: 100%;
  position: relative;
}
.course-container .course-selector-utility .offstage {
  display: none;
}
.course-container .course-selector-utility > header {
  padding: 1rem;
  text-align: center;
  background-color: #4B7A60;
  color: white;
}
.course-container .course-selector-utility > main {
  position: absolute;
  background-color: white;
  z-index: 500;
  top: 60px;
  width: 100%;
}
@keyframes fade-in {
  0% {
    opacity: 0;
    height: 0;
  }
  100% {
    opacity: 1;
    height: 100%;
  }
}
.course-container .course-lesson-bar {
  display: flex;
  flex-flow: column;
  opacity: 1;
  animation: fade-in 0.5s ease-in;
}
.course-container .course-lesson-bar.offstage {
  opacity: 0;
}
.course-container .course-lesson-bar > div {
  min-height: 5.65vh;
  border-bottom: 1px solid #DDDDDD;
  -webkit-user-select: none;
          user-select: none;
}
@media (max-width: 991.98px) {
  .course-container .course-lesson-bar > div {
    min-height: 0;
  }
}
.course-container .course-lesson-bar .course-dropdown {
  cursor: pointer;
}
.course-container .course-lesson-bar .course-dropdown .accordion-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  color: #3A424C;
  font-weight: 600;
  padding: 2rem 1.27rem 2rem 0;
  min-height: 83px;
}
@media (max-width: 991.98px) {
  .course-container .course-lesson-bar .course-dropdown .accordion-title {
    padding: 1rem;
  }
}
.course-container .course-lesson-bar .course-dropdown .accordion-title .rightSide {
  min-width: 45px;
  text-align: right;
}
.course-container .course-lesson-bar .course-dropdown .accordion-title .rightSide svg {
  margin-left: 8px;
}
.course-container .course-lesson-bar .course-dropdown .accordion-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  color: #3A424C;
  padding: 1rem 1.27rem 1rem 0;
  min-height: 83px;
  border-top: 1px solid #ddd;
}
.course-container .course-lesson-bar .course-dropdown .accordion-item .duration {
  padding: 0;
  text-align: right;
}
.course-container .course-lesson-bar .course-dropdown .accordion-item .duration.mobile {
  text-align: left;
  padding-left: 25px;
}
.course-container .course-lesson-bar .course-dropdown .accordion-item .infoSign {
  padding: 0;
  text-align: right;
}
.course-container .course-lesson-bar .course-dropdown .accordion-item .infoSign.mobile {
  text-align: left;
  padding-left: 25px;
}
@media (max-width: 991.98px) {
  .course-container .course-lesson-bar .course-dropdown .accordion-item .accordion-item, .course-container .course-lesson-bar .course-dropdown .accordion-item .padding-title {
    padding: 1rem;
  }
  .course-container .course-lesson-bar .course-dropdown .accordion-item .accordion-item .title, .course-container .course-lesson-bar .course-dropdown .accordion-item .padding-title .title {
    min-height: 0;
  }
}
.course-container .course-lesson-bar .course-dropdown .focused {
  background-color: #1C6240;
  color: white;
  font-weight: 600;
}
.course-container .course-window {
  padding: 3.97rem 5.89rem 4rem;
  background: #F7F7F7;
  position: relative;
  z-index: 5;
}
@media (max-width: 1199.98px) {
  .course-container .course-window {
    padding: 2.48rem 2.48rem 0;
  }
}
@media (max-width: 991.98px) {
  .course-container .course-window {
    padding: 1rem 1rem 0;
  }
}
.course-container .course-window .course-overview {
  max-width: 80%;
  min-height: 300px;
  padding: 2.48rem 2.48rem 0;
}
@media (max-width: 1199.98px) {
  .course-container .course-window .course-overview {
    max-width: 100%;
  }
}
.course-container .course-window .course-overview h2 {
  margin: 0;
  color: #3A424C;
  font-size: 2.27rem;
}
.course-container .course-window .course-overview p {
  font-weight: 500;
  margin-top: 2.5rem;
}
.course-container .course-window .course-overview img {
  max-width: 100%;
}
.course-container .course-window .resource-container {
  width: 100%;
  background-color: white;
  border-radius: 8px;
  padding: 3% 6%;
  box-shadow: 0 4px 10px rgba(15, 51, 71, 0.05), 0 2px 3px rgba(15, 51, 71, 0.05), 0 0 1px rgba(15, 51, 71, 0.15);
  display: flex;
  flex-direction: column;
}
.course-container .course-window .resource-container h4 {
  margin: 0;
  color: #3A424C;
  font-size: 1.49rem;
  margin-bottom: 1.13rem;
}
.course-container .course-window .resource-container h3 {
  margin: 0;
  color: #3A424C;
  font-size: 1.49rem;
  padding-bottom: 1.7rem;
}
.course-container .course-window .resource-container p {
  margin: 0;
  color: #3A424C;
}
.course-container .course-window .resource-container .resource-subheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.course-container .course-window .resource-container .resource-subheader h2 {
  margin: 0;
  color: #3A424C;
  font-size: 2.27rem;
}
.course-container .course-window .resource-container .resource-subheader a {
  color: #525659;
  cursor: pointer;
}
.course-container .course-window .resource-container .resource-subheader span {
  padding-right: 1rem;
}
.course-container .course-window .resource-container .player {
  width: 100%;
  margin: 3.4rem 0;
}
.course-container .course-window .resource-container .ctaContent {
  width: 100%;
  margin: 3.4rem 0;
}
.course-container .course-window .resource-container .ctaContent img {
  width: 100%;
}
.course-container .course-window .resource-container .lesson-summary p {
  font-weight: 500;
}
.course-container .course-window .resource-container .resource-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 5.11rem;
}
.course-container .course-window .resource-container .resource-nav a {
  cursor: pointer;
}
.course-container .course-window .resource-container .resource-nav span {
  padding: 0 0.35rem;
  color: #525659;
}
.course-container .course-window .resource-container .resource-nav svg {
  margin: 0;
  color: #3A424C;
}
.course-container .course-window .resource-container .resource-nav .btn-navlink {
  background: none;
  border: none;
}
.course-container .course-window .resource-container .resource-nav .btn-navlink:hover {
  text-decoration: none;
}
.course-container .course-window .resource-container .nav-first {
  flex-direction: row-reverse;
}
.course-container .page {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
.course-container .page .row {
  flex: 1 1;
}
@media (max-width: 991.98px) {
  .course-container .page .row {
    display: flex;
    flex-direction: column;
  }
}

.siteContainer > header {
  border-top: 5px solid #0c3f57;
  background-color: #f3f3f3;
}
@media (max-width: 992px) {
  .siteContainer > header {
    border-top: 0;
  }
}
.siteContainer > header .hamburger-inner, .siteContainer > header .hamburger-inner::before, .siteContainer > header .hamburger-inner::after {
  background-color: #000;
}
.siteContainer > header .menu li {
  padding-right: 1rem;
}
.siteContainer > header .menu li a {
  padding: 0 0.5rem;
  color: #4E4E4E;
  font-size: 0.95rem;
  font-weight: 500;
}
.siteContainer > header .menu li a:hover {
  color: #9a9a9a;
}
.siteContainer > header .menu li a:visited {
  color: #4E4E4E;
}
.siteContainer > header .menu li a:visited:hover {
  color: #9a9a9a;
}
.siteContainer > header .menu .loginBtn {
  margin-left: 10px;
}
.siteContainer > header .menu .loginBtn a {
  color: #000;
  font-weight: 800;
  line-height: 27.5px;
  padding: 8px 20px;
}
.siteContainer > header .menu .loginBtn a:hover {
  background-color: #000;
  color: white;
}
.siteContainer > header .menu .myAccountButton {
  padding: 0;
}
.siteContainer > header .menu .myAccountButton > a {
  color: white;
  padding: 0.3rem 1.5rem;
  display: block;
}
.siteContainer > header .menu .myAccountButton > ul {
  overflow: scroll;
}
.siteContainer > header .menu .myAccountButton .myAccountDropdown a {
  color: #4E4E4E;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 27.5px;
  z-index: 999;
}
.siteContainer > header .menu .myAccountButton .myAccountDropdown a:hover {
  color: #9a9a9a;
}
.siteContainer > header .menu .myAccountButton .myAccountDropdown a:focus {
  color: #1A6690;
}
.siteContainer > header .menu .myAccountButton .myAccountDropdown .sub a {
  font-weight: normal;
}
.siteContainer > header .menu .myAccountButton .myAccountDropdown .sub a:hover {
  color: #9a9a9a;
}
.siteContainer > header .menu .myAccountButton .myAccountDropdown .sub a:focus {
  color: #1A6690;
}
.siteContainer > header .searchToggle {
  color: #4E4E4E;
  font-family: Roboto, sans-serif;
}
@media (max-width: 1199.98px) {
  .siteContainer > header .searchToggle {
    font-size: 0.95rem;
  }
}
.siteContainer > header .searchToggle:hover {
  color: #9a9a9a;
}

.mainSectionWrapper {
  min-height: 600px;
  transition: margin-left 0.5s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
.mainSectionWrapper p {
  color: #212529;
  line-height: 2rem;
}
.mainSectionWrapper .publicationMessage .alert-warning p {
  color: #856404;
  line-height: 21.5px;
}
.mainSectionWrapper .publicationMessage .alert {
  padding: 0.5rem 0.75rem;
}
.mainSectionWrapper .bigBannerWrapper {
  max-width: 100%;
  height: 437px;
  background-image: url(//files.legacyresearch.com/site/img/assets/lrg-homepage-slider.png);
  background-repeat: no-repeat;
  background-position: center top;
}
@media (max-width: 1199.98px) {
  .mainSectionWrapper .bigBannerWrapper {
    height: inherit;
    width: 100%;
    background-image: url(//files.legacyresearch.com/site/img/assets/lrg-slider.png), linear-gradient(to bottom, rgb(4, 92, 139) 0%, rgb(13, 139, 179) 16%, rgb(13, 139, 179) 50%, rgb(3, 93, 137) 100%);
    background-size: cover;
  }
}
@media (max-width: 767.98px) {
  .mainSectionWrapper .bigBannerWrapper {
    background: linear-gradient(to bottom, rgb(4, 92, 139) 0%, rgb(13, 139, 179) 16%, rgb(13, 139, 179) 50%, rgb(3, 93, 137) 100%);
  }
}
.mainSectionWrapper .bigBannerWrapper .blurb {
  font-size: 1.75em;
  font-weight: 400;
}
.mainSectionWrapper .bigBannerWrapper .blurb p {
  margin-top: 2.6em;
  line-height: 1.92em;
  color: white;
  margin-bottom: 20px;
  padding: 0;
}
@media (max-width: 991.98px) {
  .mainSectionWrapper .bigBannerWrapper .blurb p {
    margin-top: 30px;
  }
}
@media (max-width: 767.98px) {
  .mainSectionWrapper .bigBannerWrapper .blurb p {
    padding: 30px;
  }
}
@media (max-width: 1199.98px) {
  .mainSectionWrapper .bigBannerWrapper .blurb {
    margin-left: 30px;
  }
}
@media (max-width: 991.98px) {
  .mainSectionWrapper .bigBannerWrapper .blurb {
    margin-left: 0;
    font-size: 1.4em;
  }
}
@media (max-width: 767.98px) {
  .mainSectionWrapper .bigBannerWrapper .blurb {
    font-size: 1.3em;
    text-align: center;
    margin: 0 auto;
  }
}
.mainSectionWrapper .sectionCTA {
  background-color: white;
  margin-top: 6em;
  margin-bottom: 6em;
}
.mainSectionWrapper .sectionCTA p {
  font-weight: 400;
  text-align: center;
  margin-bottom: 25px;
}
.mainSectionWrapper .sectionCTA a {
  text-align: center;
  display: block;
}
.mainSectionWrapper .sectionCTA a button {
  background-color: #EEA445;
  border-color: #EEA445;
  color: white;
  padding: 10px 70px;
  border-radius: 4px;
  font-size: 19px;
}
@media (max-width: 1199.98px) {
  .mainSectionWrapper .sectionCTA a button {
    width: 100%;
  }
}
.mainSectionWrapper .sectionCTA a button:hover {
  background-color: #e49025;
}
.mainSectionWrapper .sectionPartners {
  background-color: #ebebeb;
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (max-width: 767.98px) {
  .mainSectionWrapper .sectionPartners {
    padding-bottom: 20px;
  }
}
.mainSectionWrapper .sectionPartners .partner {
  text-align: center;
}
@media (max-width: 991.98px) {
  .mainSectionWrapper .sectionPartners .partner {
    margin-bottom: 40px;
  }
}
@media (max-width: 767.98px) {
  .mainSectionWrapper .sectionPartners .partner {
    margin-bottom: 60px;
  }
}
.mainSectionWrapper .sectionPartners .partner img {
  width: 75px;
  height: 75px;
}
.mainSectionWrapper .sectionPartners .partner h2 {
  font-weight: 700;
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 12px;
}
.mainSectionWrapper .sectionNewsletter {
  background-color: white;
  padding-top: 60px;
  padding-bottom: 60px;
}
.mainSectionWrapper .sectionNewsletter h3 {
  margin-top: 20px;
  font-size: 21px;
  font-weight: 700;
  font-family: "Roboto Slab", sans-serif;
}
.mainSectionWrapper .sectionNewsletter p {
  margin-top: 15px;
}
.mainSectionWrapper .sectionNewsletter .banner {
  text-align: center;
}
.mainSectionWrapper .sectionNewsletter .banner img {
  max-width: 100%;
  width: auto;
}
.mainSectionWrapper .sectionNewsletter .input-group {
  max-width: 400px;
}
@media (max-width: 991.98px) {
  .mainSectionWrapper .sectionNewsletter .input-group {
    max-width: inherit;
  }
}
.mainSectionWrapper .sectionNewsletter .input-group button {
  font-size: 16px;
  background-color: #136592;
  border-color: #136592;
  color: white;
}
.mainSectionWrapper .sectionNewsletter .input-group button:hover {
  background-color: rgba(19, 101, 146, 0.6);
}
.mainSectionWrapper .sectionPosts {
  padding-top: 100px;
  padding-bottom: 60px;
  border-top: 1px solid #ebebeb;
}

#__page_login, #__page_reset {
  padding-top: 3rem;
  padding-bottom: 1.5rem;
}
#__page_login .loginMsgContainer, #__page_reset .loginMsgContainer {
  background-color: #fff3cd;
  border-color: #ffeeba;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  margin: 0 auto 50px auto;
}
#__page_login .loginMsgContainer .loginMsgWrapper, #__page_reset .loginMsgContainer .loginMsgWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
#__page_login .loginMsgContainer .loginMsgWrapper > p, #__page_reset .loginMsgContainer .loginMsgWrapper > p {
  color: #856404;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0;
}
#__page_login .recaptchaWrapper > div > div > div, #__page_reset .recaptchaWrapper > div > div > div {
  margin: 0 auto;
}
@media (max-width: 575.98px) {
  #__page_login .recaptchaWrapper, #__page_reset .recaptchaWrapper {
    transform: scale(0.85);
    -webkit-transform: scale(0.85);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}
#__page_login .invalid-feedback, #__page_reset .invalid-feedback {
  display: block;
  height: 20px;
}
#__page_login .actions, #__page_reset .actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  border: 1px solid #c1c1c1;
  border-top: 5px solid #07547e;
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 570px;
  margin: 0 auto;
  border-radius: 5px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
}
@media (max-width: 600px) {
  #__page_login .actions, #__page_reset .actions {
    margin-right: 15px;
    margin-left: 15px;
  }
}
@media (max-width: 575.98px) {
  #__page_login .actions, #__page_reset .actions {
    max-width: 290px;
    margin: 0 auto;
  }
}
#__page_login .actions > section, #__page_reset .actions > section {
  text-align: center;
}
#__page_login .actions > section h4, #__page_reset .actions > section h4 {
  color: #07547e;
  text-align: center;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 30px;
}
#__page_login .actions > section form, #__page_reset .actions > section form {
  max-width: 430px;
  margin: 0 auto;
}
#__page_login .actions > section input[type=text], #__page_reset .actions > section input[type=text] {
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  text-align: center;
  color: #666666;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #B8B8B8;
  border-radius: 0.25rem;
  box-shadow: inset 0px 1px 0px 0px #D7D7D7;
  margin-bottom: 10px;
}
#__page_login .actions > section input[type=password], #__page_reset .actions > section input[type=password] {
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  color: #666666;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #B8B8B8;
  border-radius: 0.25rem;
  box-shadow: inset 0px 1px 0px 0px #D7D7D7;
  margin-bottom: 10px;
}
#__page_login .actions > section button[type=submit], #__page_reset .actions > section button[type=submit] {
  width: 100%;
  background-color: #136592;
  border-color: #07547e;
  color: white;
  font-weight: bold;
  font-size: 19px;
  padding: 10px 0px 10px 0px;
}
#__page_login .actions > section button[type=submit]:hover, #__page_reset .actions > section button[type=submit]:hover {
  background-color: #07547e;
  border-color: #07547e;
}
#__page_login .actions > section button[type=submit]:focus, #__page_login .actions > section button[type=submit]:active, #__page_reset .actions > section button[type=submit]:focus, #__page_reset .actions > section button[type=submit]:active {
  background: #023C5B;
}
#__page_login .actions > section input[type=checkbox], #__page_reset .actions > section input[type=checkbox] {
  margin-top: 8px;
}
#__page_login .actions > section .lastLine .form-check, #__page_reset .actions > section .lastLine .form-check {
  text-align: left;
}
#__page_login .actions > section .lastLine label, #__page_reset .actions > section .lastLine label {
  font-size: 12px;
}
#__page_login .actions > section .lastLine .passReminder, #__page_reset .actions > section .lastLine .passReminder {
  text-align: center;
  margin-top: 15px;
}
#__page_login .actions > section .lastLine .passReminder a, #__page_login .actions > section .lastLine .passReminder label, #__page_reset .actions > section .lastLine .passReminder a, #__page_reset .actions > section .lastLine .passReminder label {
  color: #136592;
}
#__page_login .smallLetters, #__page_reset .smallLetters {
  text-align: center;
  margin-top: 20px;
}
#__page_login .smallLetters a, #__page_reset .smallLetters a {
  color: #136592;
}
#__page_login .smallLetters p, #__page_reset .smallLetters p {
  max-width: 600px;
  margin: 0 auto;
  line-height: 20.5px;
  color: #66666A;
}

.r-modal-login-overlay {
  background-color: rgba(255, 255, 255, 0.75);
}

.r-modal-login-modal {
  margin-top: 60px;
  border-radius: 8px;
  border: 1px solid lightgray;
  padding: 40px 80px;
  max-height: 70vh;
  overflow-y: auto;
}
@media (max-width: 767.98px) {
  .r-modal-login-modal {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.r-modal-login-modal h4 {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
}
.r-modal-login-modal p {
  font-size: 17px;
  line-height: 27.5px;
  font-weight: 400;
  text-shadow: none;
  margin-bottom: 21px;
  font-family: "Roboto", sans-serif;
}
.r-modal-login-modal ul {
  list-style: none;
}
.r-modal-login-modal li {
  font-size: 1.4em;
  margin-top: 20px;
}

.r-modal-login-close {
  position: fixed;
  top: 100px;
  right: auto;
  margin-left: 675px;
}
@media (max-width: 836px) {
  .r-modal-login-close {
    top: 100px;
    right: 5%;
    margin-left: 0;
  }
}
.r-modal-login-close svg {
  fill: lightgray;
}
.r-modal-login-close svg:hover {
  fill: darkgrey;
  cursor: pointer;
}

#__page_logout h4 {
  display: block;
  max-width: 200px;
  margin: 60px auto;
}

.__page_search .result {
  padding: 10px;
  margin-top: 15px;
}
.__page_search .result header .articleTitle a {
  color: black;
  font-weight: 700;
}
.__page_search .result header .articleInfo {
  color: #888888;
}
.__page_search .result header .articleInfo .splitter {
  padding-left: 10px;
  padding-right: 10px;
}
.__page_search .result header .articleType {
  text-transform: capitalize;
}
.__page_search .result footer .articleLink {
  padding-bottom: 15px;
}
.__page_search .beforeResults .searchWrapper {
  width: 100%;
  display: flex;
  margin-bottom: 30px;
}
.__page_search .beforeResults .searchWrapper .searchBoxWrapper {
  flex: 1 1;
}
.__page_search .beforeResults .searchWrapper .searchBoxWrapper .searchBox {
  height: 40px;
  color: #666666 !important;
  box-sizing: border-box;
  outline: none;
  text-align: left !important;
  font-size: 15px;
  width: 100%;
  padding: 10px;
  border: 0.5px solid #9A9A9A;
  border-radius: 3px 0 0 3px;
  background-color: #FFFFFF;
  box-shadow: inset 0 1px 0 1px #D7D7D7;
}
.__page_search .beforeResults .searchWrapper .searchBtn {
  height: 40px;
  background: #1a6690;
  border: 0.5px solid #1a6690;
  border-radius: 0 3px 3px 0;
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 700 !important;
  font-family: Roboto, arial, sans-serif;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  white-space: nowrap;
  font-size: 15px;
  line-height: 1.42857;
  -webkit-user-select: none;
          user-select: none;
  color: white;
}
.__page_search .beforeResults .searchWrapper .searchOptions {
  padding: 15px 5px;
}
.__page_search .beforeResults .searchWrapper .searchOptions .radios > span {
  font-weight: bold;
}
.__page_search .beforeResults .searchWrapper .searchOptions .radios input {
  margin-left: 10px;
}
.__page_search .actions {
  margin-top: 10px;
  margin-bottom: 10px;
}
.__page_search .actions button {
  color: #07547e;
  font-size: 17px;
  font-weight: 500;
  text-decoration: none;
  padding: 6px 0;
  display: block;
  margin: 0 auto;
  min-width: 200px;
  background-color: white;
  border-color: #07547e;
  border-style: solid;
}

#__page_contact .headerWrapper, #__page_feedback .headerWrapper, #__page_favorites .headerWrapper, #__page_bonus .headerWrapper {
  padding-bottom: 10px;
  margin-bottom: 25px;
  border-bottom: 1px solid #C1C1C1;
}
@media (max-width: 767.98px) {
  #__page_contact .headerWrapper, #__page_feedback .headerWrapper, #__page_favorites .headerWrapper, #__page_bonus .headerWrapper {
    padding-left: 10px;
  }
}
#__page_contact .headerWrapper .contactMessage .alert-warning p, #__page_feedback .headerWrapper .contactMessage .alert-warning p, #__page_favorites .headerWrapper .contactMessage .alert-warning p, #__page_bonus .headerWrapper .contactMessage .alert-warning p {
  color: #856404;
  line-height: 21.5px;
}
#__page_contact .preamble, #__page_feedback .preamble, #__page_favorites .preamble, #__page_bonus .preamble {
  padding-bottom: 20px;
}
#__page_contact .notice, #__page_feedback .notice, #__page_favorites .notice, #__page_bonus .notice {
  color: #9b9b9b;
  font-size: 15px;
}
#__page_contact .rightSide, #__page_feedback .rightSide, #__page_favorites .rightSide, #__page_bonus .rightSide {
  padding: 2rem;
  margin-left: 1rem;
}
#__page_contact .rightSide header, #__page_feedback .rightSide header, #__page_favorites .rightSide header, #__page_bonus .rightSide header {
  margin-bottom: 40px;
}
#__page_contact .rightSide h3, #__page_feedback .rightSide h3, #__page_favorites .rightSide h3, #__page_bonus .rightSide h3 {
  margin-bottom: 20px;
}
#__page_contact .rightSide__contact-info, #__page_feedback .rightSide__contact-info, #__page_favorites .rightSide__contact-info, #__page_bonus .rightSide__contact-info {
  align-items: center;
  padding: 0.5rem;
  margin-left: 0.5rem;
}
#__page_contact .rightSide__contact-info svg, #__page_feedback .rightSide__contact-info svg, #__page_favorites .rightSide__contact-info svg, #__page_bonus .rightSide__contact-info svg {
  color: #a8a6a6;
  margin-right: 1.5rem;
  font-size: 20px;
}
#__page_contact .rightSide__contact-info span, #__page_feedback .rightSide__contact-info span, #__page_favorites .rightSide__contact-info span, #__page_bonus .rightSide__contact-info span {
  font-size: 1rem;
}
#__page_contact .sendBtn, #__page_feedback .sendBtn, #__page_favorites .sendBtn, #__page_bonus .sendBtn {
  background-color: #4a4a4a;
  color: white;
}
#__page_contact .thanksWrapper, #__page_feedback .thanksWrapper, #__page_favorites .thanksWrapper, #__page_bonus .thanksWrapper {
  display: flex;
  min-height: 300px;
  justify-content: center;
  align-items: center;
}
#__page_contact .thanksWrapper p, #__page_feedback .thanksWrapper p, #__page_favorites .thanksWrapper p, #__page_bonus .thanksWrapper p {
  color: #4E4E4E;
  font-size: 1.3em;
  font-weight: bold;
}

.form-contact {
  background-color: #f5f5f5;
  padding: 2rem 3rem;
  margin: 0 1rem;
  border-radius: 3px;
}

.new-form-contact form {
  padding: 2rem 3rem;
  background-color: #f5f5f5;
  border-radius: 3px;
  margin: 0 1rem;
}
.new-form-contact form .fieldWrapper {
  margin-bottom: 1rem;
}
.new-form-contact form .error {
  color: orangered;
  margin-bottom: 1rem;
  margin-top: -1rem;
  padding-left: 5px;
}

.faq-btn {
  height: 6rem;
  width: 8rem;
  background-color: #1A6690;
  margin: 0 1rem;
  color: white;
  font-weight: 700;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.faq-btn > svg {
  font-size: 2rem;
}

#genralFaqs {
  position: absolute;
  left: 0px;
  top: -60px;
}

@media (max-width: 767.98px) {
  .rightSide__contact-info {
    margin-left: 0.5px;
  }
  #__page_contact .rightSide {
    margin-left: 0;
  }
  .faq-btn {
    margin: 0 0.5rem;
  }
}
.r-modal-product-bonus h5 {
  margin-top: 30px;
}
.r-modal-product-bonus ul {
  margin-top: 20px;
}
.r-modal-product-bonus ul li {
  font-size: 17px;
  line-height: 27.5px;
  font-weight: 400;
  text-shadow: none;
  font-family: "Roboto", sans-serif;
}
.r-modal-product-bonus .bonusPopupImage {
  max-width: 600px;
  max-height: 300px;
  margin-left: 20px;
}

@supports (-moz-appearance: none) {
  @media only print {
    .row {
      display: block !important;
    }
  }
}
#__template_single.__portfolios ul.boxedItemsList {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}
#__template_single.__portfolios ul.boxedItemsList li.boxedItem {
  margin-bottom: 20px;
}
#__template_single.__portfolios ul.boxedItemsList li.boxedItem a {
  text-transform: uppercase;
  padding: 10px 50px 10px 10px;
  border: 2px solid #F5F5F1;
  margin-top: 10px;
  list-style-type: none;
  font-size: 16px;
  display: block;
  color: #000000;
}

#__template_single.__portfolio {
  font-size: 0.9em;
}
#__template_single.__portfolio .alert {
  font-size: 14.4px;
}
@media only screen {
  #__template_single.__portfolio header {
    margin-bottom: 40px;
  }
  #__template_single.__portfolio header .portfolioDropdown {
    max-width: 400px;
  }
}
@media only screen and (max-width: 991.98px) {
  #__template_single.__portfolio table, #__template_single.__portfolio thead, #__template_single.__portfolio tbody, #__template_single.__portfolio th, #__template_single.__portfolio td, #__template_single.__portfolio tr {
    display: block;
  }
  #__template_single.__portfolio thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  #__template_single.__portfolio tr {
    border: 1px solid #ccc;
  }
  #__template_single.__portfolio td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }
  #__template_single.__portfolio td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    content: attr(data-title);
  }
  #__template_single.__portfolio tbody tr {
    margin-bottom: 20px;
  }
  #__template_single.__portfolio tbody tr:hover {
    background-color: whitesmoke;
  }
  #__template_single.__portfolio tbody tr td:first-child {
    background-color: #f1f1f1;
  }
}

#__template_two_column.__portfolios ul.boxedItemsList {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}
#__template_two_column.__portfolios ul.boxedItemsList li.boxedItem {
  margin-bottom: 20px;
}
#__template_two_column.__portfolios ul.boxedItemsList li.boxedItem a {
  text-transform: uppercase;
  padding: 10px 50px 10px 10px;
  border: 2px solid #F5F5F1;
  margin-top: 10px;
  list-style-type: none;
  font-size: 16px;
  display: block;
  color: #000000;
}

#__template_two_column.__portfolio {
  font-size: 0.9em;
}
#__template_two_column.__portfolio .alert {
  font-size: 14.4px;
}
@media only screen {
  #__template_two_column.__portfolio header {
    margin-bottom: 40px;
  }
  #__template_two_column.__portfolio header .portfolioDropdown {
    max-width: 400px;
  }
}
@media only screen and (max-width: 991.98px) {
  #__template_two_column.__portfolio table, #__template_two_column.__portfolio thead, #__template_two_column.__portfolio tbody, #__template_two_column.__portfolio th, #__template_two_column.__portfolio td, #__template_two_column.__portfolio tr {
    display: block;
  }
  #__template_two_column.__portfolio thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  #__template_two_column.__portfolio tr {
    border: 1px solid #ccc;
  }
  #__template_two_column.__portfolio td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }
  #__template_two_column.__portfolio td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    content: attr(data-title);
  }
  #__template_two_column.__portfolio tbody tr {
    margin-bottom: 20px;
  }
  #__template_two_column.__portfolio tbody tr:hover {
    background-color: whitesmoke;
  }
  #__template_two_column.__portfolio tbody tr td:first-child {
    background-color: #f1f1f1;
  }
}

@supports (-moz-appearance: none) {
  @media only print {
    .row {
      display: block !important;
    }
  }
}
#__template_single.withPortfolio .postfixPortfolio {
  font-size: 0.9em;
}
@media only screen and (max-width: 991.98px) {
  #__template_single.withPortfolio .postfixPortfolio table, #__template_single.withPortfolio .postfixPortfolio thead, #__template_single.withPortfolio .postfixPortfolio tbody, #__template_single.withPortfolio .postfixPortfolio th, #__template_single.withPortfolio .postfixPortfolio td, #__template_single.withPortfolio .postfixPortfolio tr {
    display: block;
  }
  #__template_single.withPortfolio .postfixPortfolio thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  #__template_single.withPortfolio .postfixPortfolio tr {
    border: 1px solid #ccc;
  }
  #__template_single.withPortfolio .postfixPortfolio td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }
  #__template_single.withPortfolio .postfixPortfolio td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    content: attr(data-title);
  }
  #__template_single.withPortfolio .postfixPortfolio tbody tr {
    margin-bottom: 20px;
  }
  #__template_single.withPortfolio .postfixPortfolio tbody tr:hover {
    background-color: whitesmoke;
  }
  #__template_single.withPortfolio .postfixPortfolio tbody tr td:first-child {
    background-color: #f1f1f1;
  }
}
#__template_single.withPortfolio .bookmarkBtn {
  background: none;
  border: none;
  margin-right: 5px;
}
#__template_single footer {
  padding-top: 10px;
  margin-top: 20px;
}

#__template_two_column.withPortfolio .postfixPortfolio {
  font-size: 0.9em;
}
@media only screen and (max-width: 991.98px) {
  #__template_two_column.withPortfolio .postfixPortfolio table, #__template_two_column.withPortfolio .postfixPortfolio thead, #__template_two_column.withPortfolio .postfixPortfolio tbody, #__template_two_column.withPortfolio .postfixPortfolio th, #__template_two_column.withPortfolio .postfixPortfolio td, #__template_two_column.withPortfolio .postfixPortfolio tr {
    display: block;
  }
  #__template_two_column.withPortfolio .postfixPortfolio thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  #__template_two_column.withPortfolio .postfixPortfolio tr {
    border: 1px solid #ccc;
  }
  #__template_two_column.withPortfolio .postfixPortfolio td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }
  #__template_two_column.withPortfolio .postfixPortfolio td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    content: attr(data-title);
  }
  #__template_two_column.withPortfolio .postfixPortfolio tbody tr {
    margin-bottom: 20px;
  }
  #__template_two_column.withPortfolio .postfixPortfolio tbody tr:hover {
    background-color: whitesmoke;
  }
  #__template_two_column.withPortfolio .postfixPortfolio tbody tr td:first-child {
    background-color: #f1f1f1;
  }
}
#__template_two_column footer {
  padding-top: 10px;
  margin-top: 20px;
}

.__page_editors .editorBox {
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  margin-bottom: 5px;
}
.__page_editors .editorBox .editorInfo {
  background: #f9f9f9;
  border-radius: 5px;
  border-bottom: 2px solid #e4e4e4;
  padding: 15px;
}
.__page_editors .editorBox .editorInfo .editorImage {
  display: flex;
  align-items: center;
}
@media (min-width: 577px) and (max-width: 800px) {
  .__page_editors .editorBox .editorInfo .editorImage {
    padding-left: 0;
  }
}
@media (max-width: 576px) {
  .__page_editors .editorBox .editorInfo .editorImage {
    padding: 0;
  }
}
.__page_editors .editorBox .editorInfo .editorName {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 576px) {
  .__page_editors .editorBox .editorInfo .editorName h2 {
    font-size: 27px;
    font-weight: 700;
    margin: 0;
  }
}
@media (max-width: 576px) {
  .__page_editors .editorBox .editorInfo .editorName h6 {
    display: none;
  }
}
.__page_editors .editorBox .editorInfo .editorButton {
  display: flex;
  align-items: center;
}
@media (min-width: 577px) and (max-width: 800px) {
  .__page_editors .editorBox .editorInfo .editorButton {
    padding-right: 0;
  }
}
@media (max-width: 576px) {
  .__page_editors .editorBox .editorInfo .editorButton {
    padding: 0;
  }
}
.__page_editors .editorBox .editorInfo .editorButton button {
  border: 2px solid #004a74;
  color: #004a74;
}
.__page_editors .editorBox .editorInfo .editorButton button:hover {
  background: #004a74;
  color: #ffffff;
}
.__page_editors .editorBox .editorDesc {
  background: none;
  padding: 10px;
}
@media (min-width: 577px) {
  .__page_editors .editorBox .editorDesc h6 {
    display: none;
  }
}

.__page_products .productsSection .sectionTitleLG, .__page_products .productsSection .sectionTitleCR, .__page_products .productsSection .sectionTitleJC, .__page_products .productsSection .sectionTitlePB, .__page_products .productsSection .sectionTitleRE, .__page_products .productsSection .sectionTitleBR, .__page_products .productsSection .sectionTitleWM {
  color: #666666;
  background-repeat: no-repeat;
  padding-left: 40px;
  line-height: 35px;
  margin-top: 35px;
  margin-bottom: 20px;
}
.__page_products .productsSection .sectionTitleLG {
  background-image: url("https://files.legacyresearch.com/site/img/logos/legacy-research-icon.png");
  padding-left: 45px;
  margin-top: 0;
}
.__page_products .productsSection .sectionTitleRE {
  background-image: url("https://files.rogueeconomics.com/site/img/logos/rogue-economics-icon.png");
}
.__page_products .productsSection .sectionTitleBR {
  background-image: url("https://files.brownstoneresearch.com/site/img/logos/brownstone-research-icon.png");
}
.__page_products .productsSection .sectionTitleNP {
  background-image: url("https://files.newparadigmresearch.com/site/img/logos/new-paradigm-research-icon.png");
  padding-left: 45px;
}
.__page_products .productsSection .sectionTitleJC {
  background-image: url("https://files.jeffclarktrader.com/site/img/logos/jeff-clark-trader-icon.png");
}
.__page_products .productsSection .sectionTitlePB {
  background-image: url("https://files.palmbeachgroup.com/site/img/logos/palm-beach-group-icon.png");
  background-size: 28px;
}
.__page_products .productsSection .sectionTitleWM {
  background-image: url("https://files.widemoatresearch.com/site/img/logos/wide-moat-research-icon.png");
}
.__page_products .productsSection .productBox::before {
  border-style: none;
}
.__page_products .productBox {
  min-height: 135px;
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  margin-bottom: 10px;
}
.__page_products .productBox::before {
  border-left-color: #07547e;
  border-right-color: transparent;
  border-top-color: #07547e;
  border-bottom-color: transparent;
  content: "";
  position: absolute;
  border-style: solid;
  border-top-left-radius: 5px;
  border-width: 1em;
}
.__page_products .productBox .productInfo {
  min-height: 135px;
  border-radius: 5px;
  border-bottom: 2px solid #e4e4e4;
  padding: 15px;
}
.__page_products .productBox .productInfo h4 {
  font-weight: 700;
}
.__page_products .productBox .productInfo .productName {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.__page_products .productBox .productInfo .productDesc {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.__page_products .productBox .productInfo .productDesc p {
  font-size: 15px !important;
  line-height: 21px !important;
  margin-bottom: 0;
}

#__page_my_subscriptions > header {
  margin-bottom: 1rem;
}
@media (min-width: 1200px) {
  #__page_my_subscriptions > header {
    padding: 20px 0;
    margin-bottom: 20px;
  }
}
@media (max-width: 1199.98px) {
  #__page_my_subscriptions > header {
    padding: 1rem 0;
  }
}
#__page_my_subscriptions > header .header-top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}
#__page_my_subscriptions > header .header-top-bar_left {
  font-weight: 600;
}
@media (max-width: 575.98px) {
  #__page_my_subscriptions > header .header-top-bar_left {
    font-size: 1rem;
  }
}
#__page_my_subscriptions > header .header-top-bar_right {
  font-weight: 400;
}
@media (max-width: 575.98px) {
  #__page_my_subscriptions > header .header-top-bar_right {
    font-size: 0.8rem;
  }
}
#__page_my_subscriptions .mySubsMsgContainer {
  background-color: #fff3cd;
  border-color: #ffeeba;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  margin: 0 auto 30px auto;
}
#__page_my_subscriptions .mySubsMsgContainer .mySubsMsgWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
#__page_my_subscriptions .mySubsMsgContainer .mySubsMsgWrapper > p {
  color: #856404;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
}
#__page_my_subscriptions section.subAdContainer {
  background-color: white;
  height: 100%;
  max-height: 100px;
  z-index: -1;
  margin-bottom: 10px;
}
#__page_my_subscriptions section.subAdContainer .subAdWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
#__page_my_subscriptions section.themeToggler {
  margin-bottom: 10px;
  text-align: right;
}
#__page_my_subscriptions section.themeToggler > .wrapper {
  border: 3px lightgreen dashed;
  display: inline-block;
  padding: 10px;
  text-align: left;
}
#__page_my_subscriptions section > header {
  margin-bottom: 1rem;
}
#__page_my_subscriptions section > header .title {
  font-weight: 200;
  color: #666666;
  width: 100%;
}
#__page_my_subscriptions section > header .separator {
  border-bottom: 1px solid #d7d7d7;
  height: 5px;
  width: 100%;
}
#__page_my_subscriptions .subscriptionsTopNWrapper,
#__page_my_subscriptions .dailyContent,
#__page_my_subscriptions .myContent,
#__page_my_subscriptions .archivedPublications {
  margin-top: 40px;
}
#__page_my_subscriptions .subscriptionsTopNWrapper a {
  color: #136592;
}
#__page_my_subscriptions .gridToggleWrapper, #__page_my_subscriptions .latestToggleWrapper {
  text-align: right;
  font-size: 0.9em;
}
#__page_my_subscriptions .gridToggleWrapper .gridBtn, #__page_my_subscriptions .gridToggleWrapper .daysBtn, #__page_my_subscriptions .latestToggleWrapper .gridBtn, #__page_my_subscriptions .latestToggleWrapper .daysBtn {
  border: none;
  opacity: 0.5;
  border-radius: 20px;
  padding: 5px 10px;
  margin-left: 5px;
  outline: none;
}
#__page_my_subscriptions .gridToggleWrapper .gridBtn.active, #__page_my_subscriptions .gridToggleWrapper .daysBtn.active, #__page_my_subscriptions .latestToggleWrapper .gridBtn.active, #__page_my_subscriptions .latestToggleWrapper .daysBtn.active {
  background-color: #d8d8d8;
  opacity: 1;
}
#__page_my_subscriptions .gridToggleWrapper .gridBtn:hover, #__page_my_subscriptions .gridToggleWrapper .daysBtn:hover, #__page_my_subscriptions .latestToggleWrapper .gridBtn:hover, #__page_my_subscriptions .latestToggleWrapper .daysBtn:hover {
  cursor: pointer;
}
#__page_my_subscriptions #latestResearchFeed {
  margin-bottom: 40px;
}
#__page_my_subscriptions #latestResearchFeed .contentArea {
  font-size: 1.1rem;
  width: 100%;
  line-height: 2.3rem;
}
@media (max-width: 767.98px) {
  #__page_my_subscriptions #latestResearchFeed .contentArea {
    margin: 0;
  }
}
#__page_my_subscriptions #latestResearchFeed .contentArea th {
  color: #b4b4b4;
  font-weight: 300;
  font-size: 0.8em;
  width: 65%;
}
#__page_my_subscriptions #latestResearchFeed .contentArea th:last-of-type {
  text-align: left;
  width: 35%;
}
#__page_my_subscriptions #latestResearchFeed .contentArea tr {
  width: 65%;
}
#__page_my_subscriptions #latestResearchFeed .contentArea tr:last-of-type {
  text-align: left;
  width: 35%;
}
#__page_my_subscriptions #latestResearchFeed .contentArea td,
#__page_my_subscriptions #latestResearchFeed .contentArea th {
  line-height: 32px;
}
@media (max-width: 575.98px) {
  #__page_my_subscriptions #latestResearchFeed .contentArea td:last-of-type,
#__page_my_subscriptions #latestResearchFeed .contentArea th:last-of-type {
    display: none;
  }
}
#__page_my_subscriptions #latestResearchFeed .contentArea .inlineIndicatorWrapper > div {
  display: inline;
  margin-right: 5px;
}
#__page_my_subscriptions .subscriptionBox {
  margin-bottom: 40px;
}
#__page_my_subscriptions .subscriptionBox .author {
  margin-bottom: 0;
}
#__page_my_subscriptions .subscriptionBox .images {
  text-align: right;
}
#__page_my_subscriptions .subscriptionBox .images img {
  max-height: 48px;
  vertical-align: middle;
}
#__page_my_subscriptions .subscriptionBox .images img:first-of-type {
  height: 48px;
}
#__page_my_subscriptions .subscriptionBox .images img:last-of-type {
  margin-left: 5px;
}
#__page_my_subscriptions .subscriptionBox .singleSiteImage {
  max-height: 48px;
  vertical-align: middle;
}
#__page_my_subscriptions .subscriptionBox .title {
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  padding: 10px;
  border: 1px solid #e4e4e4;
  border-bottom: 5px solid #e4e4e4;
  background: #f9f9f9;
  margin-top: 10px;
  margin-bottom: 10px;
}
#__page_my_subscriptions .subscriptionBox ul {
  padding: 0;
  list-style-type: none;
}
#__page_my_subscriptions .subscriptionBox ul li {
  padding: 5px 0;
  list-style-type: none;
}
#__page_my_subscriptions .subscriptionBox ul li a {
  font-size: 17px;
  line-height: 25px;
  color: #1a6690;
}
#__page_my_subscriptions .subscriptionBox ul li a:hover, #__page_my_subscriptions .subscriptionBox ul li a:visited {
  color: #1a6690;
}
#__page_my_subscriptions .productSiteWrapper.mode-list {
  width: 100%;
  background-color: white;
}
#__page_my_subscriptions .productSiteWrapper.mode-list header {
  margin-top: 30px;
  margin-bottom: 20px;
}
#__page_my_subscriptions .productSiteWrapper.mode-list header img {
  height: 32px;
}
#__page_my_subscriptions .productSiteWrapper.mode-list header h5 {
  display: inline;
  padding-left: 10px;
  color: #666666;
  vertical-align: middle;
}
#__page_my_subscriptions .subscriptionRow {
  margin-bottom: 5px;
  display: block;
}
#__page_my_subscriptions .subscriptionRow .itemWrapper {
  display: flex;
  align-items: center;
  font-weight: bold;
  line-height: 40px;
  padding: 10px;
  border-bottom: 1px solid #e4e4e4;
  background: #f9f9f9;
  margin-top: 10px;
  flex-wrap: wrap;
  border-radius: 3px 3px 0 0;
}
#__page_my_subscriptions .subscriptionRow .itemWrapper > div {
  flex: 0 1;
}
#__page_my_subscriptions .subscriptionRow .itemWrapper > div.toggler {
  margin-left: 10px;
  margin-right: 10px;
  color: #666666;
}
#__page_my_subscriptions .subscriptionRow .itemWrapper > div.toggler:hover {
  cursor: pointer;
}
#__page_my_subscriptions .subscriptionRow .itemWrapper > div.image img,
#__page_my_subscriptions .subscriptionRow .itemWrapper > div.image .imageStub {
  min-height: 48px;
  height: 48px;
  vertical-align: middle;
  margin-left: 10px;
  margin-right: 10px;
}
#__page_my_subscriptions .subscriptionRow .itemWrapper > div.image .imageStub {
  width: 48px;
}
#__page_my_subscriptions .subscriptionRow .itemWrapper > div.person {
  min-width: 250px;
  padding-left: 10px;
  font-weight: 400;
}
@media (max-width: 767.98px) {
  #__page_my_subscriptions .subscriptionRow .itemWrapper > div.person {
    display: none;
  }
}
#__page_my_subscriptions .subscriptionRow .itemWrapper > div.publication {
  flex: 1 1;
  font-weight: 700;
}
@media (max-width: 1199.98px) {
  #__page_my_subscriptions .subscriptionRow .itemWrapper > div.publication {
    min-width: 600px;
    flex-basis: auto;
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  #__page_my_subscriptions .subscriptionRow .itemWrapper > div.publication {
    min-width: inherit;
    flex-basis: auto;
    text-align: left;
    padding-left: 4%;
  }
}
@media (max-width: 575.98px) {
  #__page_my_subscriptions .subscriptionRow .itemWrapper > div.publication {
    padding-left: 2%;
    font-size: 0.85em;
  }
}
#__page_my_subscriptions .subscriptionRow .itemWrapper > div.publication a {
  color: #212529;
}
#__page_my_subscriptions .subscriptionRow .itemWrapper > div.publication a:visited {
  color: #212529;
}
#__page_my_subscriptions .subscriptionRow .itemWrapper > div.actions {
  margin-left: 5px;
}
#__page_my_subscriptions .subscriptionRow .itemWrapper > div.actions svg {
  width: 20px;
}
#__page_my_subscriptions .subscriptionRow .itemWrapper > div.submenu {
  flex: 1 1;
  text-align: center;
}
@media (max-width: 1199.98px) {
  #__page_my_subscriptions .subscriptionRow .itemWrapper > div.submenu {
    min-width: 400px;
    flex-basis: auto;
  }
}
@media (max-width: 767.98px) {
  #__page_my_subscriptions .subscriptionRow .itemWrapper > div.submenu {
    display: none;
  }
}
#__page_my_subscriptions .subscriptionRow .itemWrapper > div.submenu a,
#__page_my_subscriptions .subscriptionRow .itemWrapper > div.submenu span {
  color: #136592;
  font-weight: 400;
}
#__page_my_subscriptions .subscriptionRow .itemWrapper > div.handler {
  color: #666666;
  padding-left: 10px;
  padding-right: 10px;
}
#__page_my_subscriptions .subscriptionRow .contentArea {
  font-size: 1.1rem;
  width: 100%;
  line-height: 2.3rem;
}
@media (max-width: 767.98px) {
  #__page_my_subscriptions .subscriptionRow .contentArea {
    margin: 0;
  }
}
#__page_my_subscriptions .subscriptionRow .contentArea th {
  color: #b4b4b4;
  font-weight: 300;
  font-size: 0.8em;
  width: 65%;
}
#__page_my_subscriptions .subscriptionRow .contentArea th:last-of-type {
  text-align: left;
  width: 35%;
}
#__page_my_subscriptions .subscriptionRow .contentArea tr {
  width: 65%;
}
#__page_my_subscriptions .subscriptionRow .contentArea tr:last-of-type {
  text-align: left;
  width: 35%;
}
#__page_my_subscriptions .subscriptionRow .contentArea td,
#__page_my_subscriptions .subscriptionRow .contentArea th {
  line-height: 32px;
}
@media (max-width: 575.98px) {
  #__page_my_subscriptions .subscriptionRow .contentArea td:last-of-type,
#__page_my_subscriptions .subscriptionRow .contentArea th:last-of-type {
    display: none;
  }
}
#__page_my_subscriptions .subscriptionRow .contentArea .inlineIndicatorWrapper > div {
  display: inline;
  margin-right: 5px;
}
#__page_my_subscriptions .myContent {
  width: 100%;
}
#__page_my_subscriptions .myContent .date {
  color: #666666;
}
#__page_my_subscriptions .myContent h1 {
  font-family: "Roboto Slab", serif;
  font-weight: bold;
  font-size: 38px;
  line-height: 44px;
  color: #000;
  margin-bottom: 12px;
  margin-top: 0;
  padding-bottom: 0;
  letter-spacing: 0;
}
#__page_my_subscriptions .myContent .links {
  text-align: right;
  margin-top: 15px;
}
@media (max-width: 991.98px) {
  #__page_my_subscriptions .myContent .links {
    text-align: center;
  }
}
#__page_my_subscriptions .myContent .links p {
  display: inline-block;
  border-right: 1px solid #d7d7d7;
  padding: 0 20px;
  margin: 0 0 10px;
  font-size: 17px;
  line-height: 27.5px;
}
@media (max-width: 991.98px) {
  #__page_my_subscriptions .myContent .links p {
    padding-left: 4%;
    padding-right: 4%;
  }
}
@media (max-width: 767.98px) {
  #__page_my_subscriptions .myContent .links p {
    padding-left: 6px;
    padding-right: 6px;
  }
}
#__page_my_subscriptions .myContent .links p:last-of-type {
  border-right: none;
}
#__page_my_subscriptions .myContent .links p a {
  color: #000;
  text-decoration: none;
}
#__page_my_subscriptions .myContent .borderline {
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
  border-bottom: 3px solid #104e6c;
  margin-bottom: 20px;
}
#__page_my_subscriptions .myContent .itemWrapper {
  margin-top: 10px;
}
#__page_my_subscriptions .myContent .itemWrapper .itemgroup .leftside h2 {
  margin: 0;
  padding: 0;
  line-height: 32px;
}
#__page_my_subscriptions .myContent .itemWrapper .itemgroup .leftside h2 a {
  color: #000;
}
#__page_my_subscriptions .myContent .itemWrapper .itemgroup .rightside h5 {
  color: #000;
  font-size: 19px;
  line-height: 24px;
  margin: 0 0 2px;
  font-weight: 500;
}
#__page_my_subscriptions .myContent .itemWrapper .itemgroup .rightside h5 a {
  color: #000;
}
#__page_my_subscriptions .myContent .itemWrapper .itemgroup .rightside h5.header {
  color: #666666;
  margin-bottom: 10px;
  font-weight: normal;
}
#__page_my_subscriptions .actionWrapper {
  text-align: center;
  margin-top: 40px;
}
#__page_my_subscriptions .actionWrapper div {
  background-color: transparent;
  border: 2px solid #07547e;
  border-radius: 3px;
  max-height: 40px;
  max-width: 410px;
  width: 100%;
  display: inline-block;
}
#__page_my_subscriptions .actionWrapper div:hover {
  background-color: #07547e;
}
#__page_my_subscriptions .actionWrapper div:hover a {
  color: white;
}
#__page_my_subscriptions .actionWrapper div:focus, #__page_my_subscriptions .actionWrapper div:active {
  background-color: #023C5B;
}
#__page_my_subscriptions .actionWrapper div:focus a, #__page_my_subscriptions .actionWrapper div:active a {
  color: white;
}
#__page_my_subscriptions .actionWrapper div a {
  font-weight: 500;
  text-decoration: none;
  padding: 6px 0;
  display: block;
}
#__page_my_subscriptions .otherProducts {
  margin-top: 20px;
  padding-bottom: 10px;
}
#__page_my_subscriptions .otherProducts header {
  border-bottom: 1px solid #d7d7d7;
}
#__page_my_subscriptions .otherProducts header h4 {
  color: #666666;
}
#__page_my_subscriptions .otherProducts .toggler {
  margin-left: 10px;
  margin-right: 10px;
  color: #666666;
  display: inline-block;
}
#__page_my_subscriptions .otherProducts .toggler:hover {
  cursor: pointer;
}
#__page_my_subscriptions .otherProducts .otherProduct {
  padding-bottom: 10px;
  border-bottom: 3px solid;
  margin-bottom: 25px;
}
#__page_my_subscriptions .otherProducts .otherProduct .title {
  font-family: "Roboto Slab", sans-serif;
}
@media (max-width: 991.98px) {
  #__page_my_subscriptions .otherProducts .otherProduct .title {
    width: 100%;
    display: block;
    text-align: center;
    padding-bottom: 20px;
  }
}
#__page_my_subscriptions .otherProducts .otherProduct .action {
  text-align: right;
  margin-top: 5px;
}
@media (max-width: 991.98px) {
  #__page_my_subscriptions .otherProducts .otherProduct .action {
    text-align: center;
  }
}
#__page_my_subscriptions .otherProducts .otherProduct .action a {
  background-color: #eea445;
  border: 0.5px solid #e49025;
  border-radius: 3px;
  display: inline-block;
  color: white;
  padding: 5px 20px;
  text-align: center;
  width: 300px;
}
#__page_my_subscriptions .otherProducts .otherProduct .action a:hover {
  background: #e49025;
  box-shadow: inset 0 -2px 0 0 #de810c;
  text-decoration: none;
}
#__page_my_subscriptions .singlePublicationViewForDashboardWrapper.isArchived .archiveRow {
  padding-bottom: 10px;
}
#__page_my_subscriptions .singlePublicationViewForDashboardWrapper.isArchived .archiveRow .title {
  font-family: "Roboto Slab", sans-serif;
}
@media (max-width: 991.98px) {
  #__page_my_subscriptions .singlePublicationViewForDashboardWrapper.isArchived .archiveRow .title {
    width: 100%;
    display: block;
    text-align: center;
    padding-bottom: 20px;
  }
}
#__page_my_subscriptions .singlePublicationViewForDashboardWrapper.isArchived .archiveRow .action {
  text-align: right;
  margin-top: 5px;
}
@media (max-width: 991.98px) {
  #__page_my_subscriptions .singlePublicationViewForDashboardWrapper.isArchived .archiveRow .action {
    text-align: center;
  }
}
#__page_my_subscriptions .singlePublicationViewForDashboardWrapper.isArchived .archiveRow .action a {
  background-color: #eea445;
  border: 0.5px solid #e49025;
  border-radius: 3px;
  display: inline-block;
  color: white;
  padding: 5px 20px;
  text-align: center;
  width: 300px;
}
#__page_my_subscriptions .singlePublicationViewForDashboardWrapper.isArchived .archiveRow .action a:hover {
  background: #e49025;
  box-shadow: inset 0 -2px 0 0 #de810c;
  text-decoration: none;
}

.k-button-custom-empty {
  background: none;
  border: none;
  color: white;
}

@media (max-width: 767.98px) {
  .subscriptionDashboard {
    padding-top: 1rem;
  }
}
.subscriptionDashboard .entry-meta .splitter {
  padding-left: 10px;
  padding-right: 10px;
}
.subscriptionDashboard .entry-meta .articleType {
  text-transform: capitalize;
}
.subscriptionDashboard .subscriber-heading {
  width: 100%;
}
@media (max-width: 991.98px) {
  .subscriptionDashboard .subscriber-heading h1 {
    text-align: center;
  }
}
.subscriptionDashboard .subscriber-heading .subscriber-heading-list {
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
}
@media (max-width: 991.98px) {
  .subscriptionDashboard .subscriber-heading .subscriber-heading-list {
    justify-content: center;
    float: none;
  }
}
@media (max-width: 767.98px) {
  .subscriptionDashboard .subscriber-heading .subscriber-heading-list {
    margin-top: 0;
  }
}
.subscriptionDashboard .subscriber-heading .subscriber-heading-list p {
  display: inline-block;
  border-right: 1px solid #d7d7d7;
  padding: 0 15px;
  margin: 0 0 10px;
  line-height: 1.2rem;
  text-align: center;
}
@media (max-width: 767.98px) {
  .subscriptionDashboard .subscriber-heading .subscriber-heading-list p {
    padding-left: 6px;
    padding-right: 6px;
  }
}
.subscriptionDashboard .subscriber-heading .subscriber-heading-list p:last-of-type {
  border-right: none;
  padding-right: 0;
}
.subscriptionDashboard .subscriber-heading .subscriber-links a {
  color: #666666;
}
.subscriptionDashboard .subscriber-heading .subscriber-links a img {
  max-width: 12px;
  width: 100%;
  margin-bottom: 3px;
  height: 12px;
  margin-right: 4px;
}
.subscriptionDashboard .subscriber-heading .subscriber-links-list {
  text-align: right;
  padding-left: 0;
}
@media (max-width: 575.98px) {
  .subscriptionDashboard .subscriber-heading .subscriber-links-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    grid-gap: 10px 10px;
    gap: 10px 10px;
    margin-top: 1rem;
    margin-bottom: 0rem;
  }
  .subscriptionDashboard .subscriber-heading .subscriber-links-list svg {
    display: none;
  }
}
.subscriptionDashboard .subscriber-heading .subscriber-links-list li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 27.5px;
}
@media (max-width: 575.98px) {
  .subscriptionDashboard .subscriber-heading .subscriber-links-list li {
    display: block;
    padding-left: 0;
    padding-right: 0;
    flex-grow: 1;
    flex-basis: 45%;
    text-align: center;
  }
}
.subscriptionDashboard .subscriber-heading .subscriber-links-list li:last-of-type {
  padding-right: 0;
}
.subscriptionDashboard .subscriber-heading .subscriber-links-list li a {
  background: #F5F5F5;
  border: 1px solid #dbdbdb;
  padding: 7px 10px;
  color: #000000;
  font-size: 1rem;
  text-decoration: none;
  font-weight: 600;
  border-bottom: 3px solid #1C6240;
}
@media (max-width: 575.98px) {
  .subscriptionDashboard .subscriber-heading .subscriber-links-list li a {
    display: block;
  }
}
.subscriptionDashboard .subscriber-heading .subscriber-links-list li a span {
  padding-left: 4px;
}
@media (max-width: 575.98px) {
  .subscriptionDashboard .subscriber-heading .subscriber-links-list li a span {
    padding-left: 0;
  }
}
.subscriptionDashboard .subscriber-heading .subscriber-links-list li a:hover {
  cursor: pointer;
}
.subscriptionDashboard .subscriber-heading .borderline {
  width: 100%;
  border-bottom: 3px solid #1C6240;
  margin-bottom: 20px;
}
.subscriptionDashboard .subscriber-issues {
  margin-top: 20px;
}
.subscriptionDashboard .subscriber-issues .title {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 31.5px;
}
.subscriptionDashboard .subscriber-issues .title hr {
  margin-top: 5px;
}
.subscriptionDashboard .subscriber-issues .itemgroup .leftside h2 {
  margin: 0 0 2px;
}
.subscriptionDashboard .subscriber-issues .itemgroup .rightside h5 {
  margin: 0 0 2px;
}
.subscriptionDashboard .subscriber-issues .actionWrapper {
  text-align: center;
  margin-top: 40px;
}
.subscriptionDashboard .subscriber-issues .actionWrapper div {
  background-color: transparent;
  border: 2px solid #07547e;
  border-radius: 3px;
  max-height: 40px;
  max-width: 410px;
  width: 100%;
  display: inline-block;
}
.subscriptionDashboard .subscriber-issues .actionWrapper div:hover {
  background-color: #07547e;
}
.subscriptionDashboard .subscriber-issues .actionWrapper div:hover a {
  color: white;
}
.subscriptionDashboard .subscriber-issues .actionWrapper div:focus, .subscriptionDashboard .subscriber-issues .actionWrapper div:active {
  background-color: #07547e;
}
.subscriptionDashboard .subscriber-issues .actionWrapper div:focus a, .subscriptionDashboard .subscriber-issues .actionWrapper div:active a {
  color: white;
}
.subscriptionDashboard .subscriber-issues .actionWrapper div a {
  color: #07547e;
  font-weight: 500;
  text-decoration: none;
  padding: 6px 0;
  display: block;
}
.subscriptionDashboard .subscriber-bulletin {
  margin-top: 20px;
}
@media (max-width: 767.98px) {
  .subscriptionDashboard .subscriber-bulletin .itemgroup {
    display: flex;
    flex-direction: column-reverse;
  }
}
.subscriptionDashboard .subscriber-bulletin .itemgroup .newArticleIndicator {
  display: inline;
  margin-right: 5px;
}
@media (min-width: 768px) {
  .subscriptionDashboard .subscriber-bulletin .itemgroup .issues {
    padding-right: 60px;
    border-right: 1px solid #D7D7D7;
  }
}
@media (max-width: 767.98px) {
  .subscriptionDashboard .subscriber-bulletin .itemgroup .actions {
    margin-bottom: 40px;
  }
}
.subscriptionDashboard .subscriber-bulletin .itemgroup .actions .action {
  padding: 5px 10px;
}
.subscriptionDashboard .subscriber-bulletin .itemgroup .actions .action:nth-child(even) {
  background-color: #f3f3f3;
}
.subscriptionDashboard .subscriber-bulletin .itemgroup .actions .action:nth-last-of-type(2) {
  margin-bottom: 4em;
}
.subscriptionDashboard .subscriber-bulletin .itemgroup .actions .action sup {
  top: 1px;
  color: #6c757d;
  font-weight: 700;
}
.subscriptionDashboard .subscriber-bulletin .itemgroup .actions .action span {
  font-size: 15px;
  line-height: 19px;
}
.subscriptionDashboard .subscriber-bulletin .itemgroup .actions .disclaimer {
  position: absolute;
  bottom: 0;
}
.subscriptionDashboard .subscriber-bulletin .actionWrapper {
  text-align: center;
  margin-top: 40px;
}
.subscriptionDashboard .subscriber-bulletin .actionWrapper div {
  background-color: transparent;
  border: 2px solid #07547e;
  border-radius: 3px;
  max-height: 40px;
  max-width: 410px;
  width: 100%;
  display: inline-block;
}
.subscriptionDashboard .subscriber-bulletin .actionWrapper div:hover {
  background-color: #07547e;
}
.subscriptionDashboard .subscriber-bulletin .actionWrapper div:hover a {
  color: white;
}
.subscriptionDashboard .subscriber-bulletin .actionWrapper div:focus, .subscriptionDashboard .subscriber-bulletin .actionWrapper div:active {
  background-color: #07547e;
}
.subscriptionDashboard .subscriber-bulletin .actionWrapper div:focus a, .subscriptionDashboard .subscriber-bulletin .actionWrapper div:active a {
  color: white;
}
.subscriptionDashboard .subscriber-bulletin .actionWrapper div a {
  color: #07547e;
  font-size: 17px;
  font-weight: 500;
  text-decoration: none;
  padding: 6px 0;
  display: block;
}
.subscriptionDashboard .subscriber-reports {
  margin-top: 40px;
}
.subscriptionDashboard .subscriber-reports .title {
  font-size: 21px;
  font-weight: 400;
  color: #666666;
  line-height: 31.5px;
}
.subscriptionDashboard .subscriber-reports .title hr {
  margin-top: 5px;
}
.subscriptionDashboard .subscriber-reports .itemgroup .leftside .wrapper {
  display: flex;
}
.subscriptionDashboard .subscriber-reports .itemgroup .leftside .wrapper .image {
  margin-right: 5%;
}
.subscriptionDashboard .subscriber-reports .itemgroup .leftside .wrapper .image img {
  height: 180px;
}
@media (max-width: 575.98px) {
  .subscriptionDashboard .subscriber-reports .itemgroup .leftside .wrapper .image img {
    height: auto;
    width: 55px;
  }
}
.subscriptionDashboard .subscriber-reports .itemgroup .leftside .wrapper .content {
  flex: 1 1;
}
@media (max-width: 575.98px) {
  .subscriptionDashboard .subscriber-reports .itemgroup .leftside .wrapper .content p {
    display: none;
  }
}
.subscriptionDashboard .subscriber-reports .itemgroup .leftside h2 {
  margin: 0;
  padding: 0;
  line-height: 32px;
}
.subscriptionDashboard .subscriber-reports .itemgroup .rightside h5 {
  margin: 0 0 2px;
}
.subscriptionDashboard .subscriber-reports .itemgroup .rightside .wrapper {
  display: flex;
}
.subscriptionDashboard .subscriber-reports .itemgroup .rightside .wrapper .image {
  margin-right: 5%;
}
.subscriptionDashboard .subscriber-reports .itemgroup .rightside .wrapper .image img {
  width: 55px;
}
.subscriptionDashboard .subscriber-reports .itemgroup .rightside .wrapper .content {
  flex: 1 1;
}
.subscriptionDashboard .subscriber-reports .actionWrapper {
  text-align: center;
  margin-top: 40px;
}
.subscriptionDashboard .subscriber-reports .actionWrapper div {
  background-color: transparent;
  border: 2px solid #07547e;
  border-radius: 3px;
  max-height: 40px;
  max-width: 410px;
  width: 100%;
  display: inline-block;
}
.subscriptionDashboard .subscriber-reports .actionWrapper div:hover {
  background-color: #07547e;
}
.subscriptionDashboard .subscriber-reports .actionWrapper div:hover a {
  color: white;
}
.subscriptionDashboard .subscriber-reports .actionWrapper div:focus, .subscriptionDashboard .subscriber-reports .actionWrapper div:active {
  background-color: #023C5B;
}
.subscriptionDashboard .subscriber-reports .actionWrapper div:focus a, .subscriptionDashboard .subscriber-reports .actionWrapper div:active a {
  color: white;
}
.subscriptionDashboard .subscriber-reports .actionWrapper div a {
  color: #07547e;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  padding: 6px 0;
  display: block;
}
.subscriptionDashboard .subscriber-resources {
  margin-top: 40px;
}
.subscriptionDashboard .subscriber-resources .title {
  font-size: 26px;
  font-weight: 400;
  color: #666666;
  margin-bottom: 20px;
}
.subscriptionDashboard .subscriber-resources .actionWrapper {
  text-align: center;
  margin-top: 40px;
}
.subscriptionDashboard .subscriber-resources .actionWrapper div {
  background-color: transparent;
  border: 2px solid black;
  border-radius: 3px;
  display: inline-block;
  margin: 0;
  max-height: 40px;
  line-height: 1;
  max-width: 410px;
  width: 100%;
}
.subscriptionDashboard .subscriber-resources .actionWrapper div a {
  padding: 10px 0;
  color: black;
  font-weight: 500;
  text-decoration: none;
  font-size: 17px;
  width: 100%;
  display: block;
}
.subscriptionDashboard .pubResourcesWrapper .subscriber-content {
  margin-top: 20px;
}
.subscriptionDashboard .pubResourcesWrapper .subscriber-content .ae-subtitle {
  font-weight: 500;
  font-size: 1.25rem;
  color: #6c757d !important;
  line-height: 31.5px;
}
.subscriptionDashboard .pubResourcesWrapper .subscriber-content hr {
  margin-top: 5px;
}
.subscriptionDashboard .pubResourcesWrapper .subscriber-content .subscriber-content-boxes h2 {
  margin: 0 0 10px;
  padding: 0;
}
.subscriptionDashboard .fullContentPosts {
  margin: 0 auto;
  padding-top: 30px;
}
.subscriptionDashboard .fullContentPosts .post {
  border-bottom: 1px solid #D7D7D7;
  padding-bottom: 25px;
  margin-bottom: 20px;
}
.subscriptionDashboard .fullContentPosts .post h6 {
  color: #888888;
  margin-bottom: 15px;
  font-weight: normal;
}
.subscriptionDashboard .fullContentPosts .btn_load_more {
  display: block;
  background: none;
  box-shadow: none;
  border: 1px solid #212529;
  margin: 40px auto 0;
  padding: 5px 30px;
}
.subscriptionDashboard .fullContentPosts .btn_load_more:hover {
  cursor: pointer;
}
.subscriptionDashboard.withChildProducts .singlePublicationViewWrapper:first-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.subscriptionDashboard.withChildProducts .singlePublicationViewWrapper:nth-child(2) {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.subscriptionDashboard.withChildProducts .singlePublicationViewWrapper.sectional {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 10px;
}
.subscriptionDashboard.withChildProducts .singlePublicationViewWrapper.sectional:last-child {
  margin-bottom: 40px;
  border-bottom: 1px solid #D7D7D7;
  padding-bottom: 20px;
}
.subscriptionDashboard.withChildProducts .isChildPublication.subscriber-issues {
  margin-top: 40px;
}
.subscriptionDashboard.withChildProducts .isChildPublication.subscriber-issues.sectional-issues .title h4 {
  font-size: 1.25rem;
  color: #6c757d !important;
}
.subscriptionDashboard.withChildProducts .isChildPublication.subscriber-issues.sectional-issues .title hr {
  border-top: 2px solid;
}

.__template_subscription_product_archive .date {
  color: #666666;
  padding-bottom: 10px;
  display: flex;
}
.__template_subscription_product_archive .articleTitle a {
  color: black;
  font-weight: 700;
}
.__template_subscription_product_archive .articleInfo {
  color: #888888;
  margin-bottom: 10px;
}
.__template_subscription_product_archive .articleInfo .splitter {
  padding-left: 10px;
  padding-right: 10px;
}
.__template_subscription_product_archive .articleType {
  text-transform: capitalize;
}
.__template_subscription_product_archive header h1 {
  font-family: "Roboto Slab", serif;
  font-weight: bold;
  line-height: 36px;
  color: #000;
  margin-bottom: 12px;
  margin-top: 0;
  padding-bottom: 0;
  letter-spacing: 0;
}
.__template_subscription_product_archive header .navigationWrapper a {
  color: #666666;
  font-size: 17px;
}
.__template_subscription_product_archive header .navigationWrapper a img {
  max-width: 12px;
  width: 100%;
  margin-bottom: 3px;
  height: 12px;
  margin-right: 4px;
}
.__template_subscription_product_archive .months {
  margin-top: 1rem;
}
.__template_subscription_product_archive .months ul {
  max-width: 300px;
  background-color: #f5f5f5;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  padding: 0 20px;
  margin: 0 0 21px;
  font-size: 17px;
  line-height: 28px;
}
.__template_subscription_product_archive .months li {
  list-style: none;
  padding: 20px 0 20px;
  border-bottom: 1px solid #d7d7d7;
  font-size: 17px;
  line-height: 28px;
}
.__template_subscription_product_archive .months li img {
  max-width: 12px;
  padding: 0;
  transform: rotate(180deg);
  margin-right: 10px;
  width: 100%;
  margin-top: -3px;
  height: 12px;
}
.__template_subscription_product_archive .months li a {
  color: #000;
  line-height: 21px;
  font-size: 17px;
  text-decoration: none;
}
.__template_subscription_product_archive .months li:last-of-type {
  border-bottom: none;
}
.__template_subscription_product_archive .posts {
  margin-top: 1rem;
}
.__template_subscription_product_archive .posts .post {
  margin-top: 10px;
  margin-bottom: 30px;
  padding-top: 10px;
  padding-bottom: 30px;
  border-bottom: 1px solid #D7D7D7;
}
.__template_subscription_product_archive .posts .post a {
  color: #000;
  text-decoration: none;
}
.__template_subscription_product_archive .posts .post .keep_reading {
  color: #1A6690;
}
.__template_subscription_product_archive .posts .post.report {
  display: flex;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #d7d7d7;
}
.__template_subscription_product_archive .posts .post.report .image {
  padding-right: 20px;
}
.__template_subscription_product_archive .posts .post.report .image img {
  width: 110px;
}
.__template_subscription_product_archive .posts .post.report .content {
  flex: 1 1;
}
.__template_subscription_product_archive .posts .post.report.sticky {
  background-color: #fffff1;
}
.__template_subscription_product_archive .posts .post .bookmarkBtn {
  background: none;
  border: none;
  margin-right: 5px;
}
.__template_subscription_product_archive main.portfolios {
  padding-top: 40px;
}
.__template_subscription_product_archive .showMoreLink:hover, .__template_subscription_product_archive .showLessLink:hover {
  cursor: pointer;
}
.__template_subscription_product_archive .btn_load_more {
  display: block;
  background: none;
  box-shadow: none;
  border: 1px solid #212529;
  margin: 40px auto 0;
  padding: 5px 30px;
}
.__template_subscription_product_archive .btn_load_more:hover {
  cursor: pointer;
}
.__template_subscription_product_archive .articleHeaderWrapper {
  display: flex;
}
.__template_subscription_product_archive .articleHeaderWrapper .articleTitle {
  flex: 80 1;
}
.__template_subscription_product_archive .articleHeaderWrapper .bookmarkAction {
  flex: 20 1;
  text-align: right;
}
.__template_subscription_product_archive section.subAdContainer {
  background-color: white;
  height: 100%;
  max-height: 100px;
  z-index: -1;
  margin-bottom: 10px;
}
.__template_subscription_product_archive section.subAdContainer .subAdWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.singlePublicationViewWrapper {
  margin-bottom: 40px;
  border-bottom: 1px solid rgb(215, 215, 215);
  padding-bottom: 20px;
}

.singlePublicationViewForDashboardWrapper {
  margin-bottom: 1rem;
  border-bottom: 1px solid rgb(215, 215, 215);
  padding-bottom: 1rem;
}
.singlePublicationViewForDashboardWrapper:last-of-type {
  border-bottom: none;
}
.singlePublicationViewForDashboardWrapper .subscriber-heading {
  margin-bottom: 1rem;
}
.singlePublicationViewForDashboardWrapper .subscriber-mixed .leftside {
  border-right: 1px solid #d7d7d7;
}
@media (max-width: 767.98px) {
  .singlePublicationViewForDashboardWrapper .subscriber-mixed .leftside {
    border: none;
  }
}
.singlePublicationViewForDashboardWrapper .subscriber-mixed .leftside .title {
  color: #666666;
  font-weight: 400;
}
.singlePublicationViewForDashboardWrapper .subscriber-mixed .leftside .newArticleIndicator {
  display: inline;
  margin-right: 5px;
}
.singlePublicationViewForDashboardWrapper .subscriber-mixed .leftside a {
  font-weight: bold;
}
@media (max-width: 767.98px) {
  .singlePublicationViewForDashboardWrapper .subscriber-mixed .rightside {
    margin-top: 20px;
  }
}
.singlePublicationViewForDashboardWrapper .subscriber-mixed .rightside .title {
  color: #666666;
  font-weight: 400;
}
@media (max-width: 767.98px) {
  .singlePublicationViewForDashboardWrapper .subscriber-mixed .rightside .title {
    padding-top: 20px;
    border-top: 2px solid #d7d7d7;
  }
}
.singlePublicationViewForDashboardWrapper .subscriber-mixed .rightside a {
  font-weight: bold;
}
.singlePublicationViewForDashboardWrapper .subscriber-mixed .rightside .report .wrapper {
  display: flex;
}
.singlePublicationViewForDashboardWrapper .subscriber-mixed .rightside .report .wrapper .image {
  margin-right: 5%;
}
.singlePublicationViewForDashboardWrapper .subscriber-mixed .rightside .report .wrapper .image img {
  width: 55px;
}
.singlePublicationViewForDashboardWrapper .subscriber-mixed .more {
  color: #666666;
  font-weight: 400;
}
.singlePublicationViewForDashboardWrapper .subscriber-mixed .more a {
  font-weight: normal;
}
.singlePublicationViewForDashboardWrapper .subscriber-heading-list {
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 991.98px) {
  .singlePublicationViewForDashboardWrapper .subscriber-heading-list {
    justify-content: center;
    text-align: center;
    float: none;
  }
}
@media (max-width: 767.98px) {
  .singlePublicationViewForDashboardWrapper .subscriber-heading-list {
    margin-top: 0;
  }
}
.singlePublicationViewForDashboardWrapper .subscriber-heading-list p {
  display: inline-block;
  border-right: 1px solid #d7d7d7;
  padding: 0 15px;
  margin: 0 0 10px;
  line-height: 27.5px;
}
.singlePublicationViewForDashboardWrapper .subscriber-heading-list p:last-of-type {
  border-right: none;
}
@media (max-width: 767.98px) {
  .singlePublicationViewForDashboardWrapper .subscriber-heading-list p {
    line-height: 1.2rem;
  }
}

.entry-meta .splitter {
  padding-left: 10px;
  padding-right: 10px;
}
.entry-meta .articleType {
  text-transform: capitalize;
}

#__subscription_account {
  padding-top: 40px;
}
#__subscription_account #subscriptionsWrapper {
  padding-bottom: 40px;
}
#__subscription_account #subscriptionsWrapper main #subscriptions {
  font-family: "Roboto", sans-serif;
  width: 100%;
}
#__subscription_account #subscriptionsWrapper main #subscriptions .subItem {
  padding-left: 20px;
}
#__subscription_account #subscriptionsWrapper main #subscriptions thead tr {
  font-size: 0.9em;
  color: gray;
  border-bottom: 1px solid #D7D7D7;
}
#__subscription_account #subscriptionsWrapper main #subscriptions tbody tr.subSection {
  border-bottom: 1px solid #f3f3f3;
}
#__subscription_account #subscriptionsWrapper main #subscriptions tbody td {
  font-size: 1.1em;
  line-height: 2em;
}
#__subscription_account #subscriptionsWrapper main #subscriptions tbody td.subHeader {
  display: flex;
  font-weight: bold;
  padding-top: 20px;
}
#__subscription_account #subscriptionsWrapper main #subscriptions tbody td.subItem {
  padding-left: 20px;
}
#__subscription_account #subscriptionsWrapper main #subscriptions tbody td.subStatus {
  vertical-align: bottom;
}
#__subscription_account #subscriptionsWrapper main #subscriptions tbody td.babySubSection .sectionTitle {
  color: gray;
  display: flex;
}
#__subscription_account #subscriptionsWrapper main #subscriptions tbody td.babySubSection .subItem {
  display: flex;
}
#__subscription_account #subscriptionsWrapper main #subscriptions tbody tr.kv {
  border-bottom: 1px solid #f3f3f3;
}
#__subscription_account #accountWrapper {
  background-color: #f3f3f3;
  padding: 20px 30px 80px;
}
#__subscription_account #accountWrapper h2 {
  font-size: 1.5em;
}
#__subscription_account #accountWrapper h5 {
  font-size: 1.1em;
  color: gray;
  margin-top: 20px;
}
#__subscription_account #accountWrapper h5 + div {
  overflow: hidden;
}
#__subscription_account #accountWrapper .addressEditor textarea, #__subscription_account #accountWrapper .addressEditor input, #__subscription_account #accountWrapper .passwordEditor textarea, #__subscription_account #accountWrapper .passwordEditor input {
  margin-top: 5px;
  width: 100%;
  border-color: lightgray;
  border-width: 1px;
  padding-left: 5px;
}
#__subscription_account .secondaryLogin {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#__subscription_account .secondaryLogin h5 {
  margin-bottom: 40px;
}
#__subscription_account .secondaryLogin p {
  max-width: 450px;
  text-align: justify;
}
#__subscription_account .secondaryLogin form {
  padding-top: 40px;
}
#__subscription_account .secondaryLogin form input {
  width: 100%;
  padding-left: 10px;
}
#__subscription_account .secondaryLogin form .error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
#__subscription_account #selectManager #subscriptions {
  font-family: "Roboto", sans-serif;
  width: 100%;
}
#__subscription_account #selectManager #subscriptions thead tr {
  font-size: 0.9em;
  color: gray;
  border-bottom: 1px solid #f3f3f3;
}
#__subscription_account #selectManager #subscriptions tbody td {
  font-size: 1.1em;
  line-height: 2em;
}
#__subscription_account #selectManager #subscriptions tbody td.subHeader {
  font-weight: bold;
  border-bottom: 1px solid #f3f3f3;
  padding-top: 20px;
}
#__subscription_account #selectManager #subscriptions tbody tr.kv {
  border-bottom: 1px solid #f3f3f3;
}
#__subscription_account #selectManager #subscriptions tbody td.babySubSection {
  padding-top: 10px;
}
#__subscription_account #selectManager #subscriptions tbody td.babySubSection .sectionTitle {
  color: gray;
  display: flex;
}
#__subscription_account #selectManager #subscriptions tbody td.babySubSection .subItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 20px;
}
#__subscription_account #selectManager #subscriptions tbody td.babySubSection .subItem:nth-of-type(odd) {
  background-color: #f3f3f3;
}
#__subscription_account #selectManager #subscriptions tbody td.babySubSection .subItem .subTitle {
  display: flex;
  flex-direction: column;
}
#__subscription_account #selectManager #subscriptions tbody td.babySubSection .subItem .subTitle a {
  color: black;
}
#__subscription_account #selectManager #subscriptions tbody td.babySubSection .subItem .subToggle {
  display: flex;
  padding-top: 5px;
}
#__subscription_account #selectManager #actions {
  margin-top: 30px;
}
#__subscription_account #selectManager #actions .message {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid red;
  color: darkred;
  font-size: 1.1em;
}
#__subscription_account #selectManager #actions .buttons {
  display: flex;
  justify-content: space-between;
}

.edit-button {
  font-size: 14px;
  display: flex;
}
.myaccount-labels {
  display: flex;
  justify-content: space-between;
}

.passwordGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sub-text {
  margin-left: 1rem;
}

.email {
  text-transform: lowercase;
}

#__page_account_ext {
  padding-top: 40px;
  font-family: "Roboto", sans-serif;
  width: 100%;
}
#__page_account_ext #accountWrapper {
  background-color: #f3f3f3;
  padding: 20px 30px 80px;
}
#__page_account_ext .secondaryLogin {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
#__page_account_ext .secondaryLogin h5 {
  margin-bottom: 40px;
}
#__page_account_ext .secondaryLogin p {
  max-width: 450px;
  text-align: justify;
}
#__page_account_ext .secondaryLogin form {
  padding-top: 40px;
  width: 100%;
  max-width: 500px;
}
#__page_account_ext .secondaryLogin form input {
  width: 100%;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
#__page_account_ext .secondaryLogin form .error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.faq {
  padding-left: 20px;
}
.faq__section {
  margin-bottom: 4rem;
}
.faq__question {
  background-color: #f5f5f5;
  width: 100%;
  border: none;
  border-bottom: solid 0.5px rgba(102, 102, 102, 0.4392156863);
  text-align: left;
  padding: 1rem;
}
.faq__question--plus {
  color: red;
}
.faq__question:focus {
  outline: none;
}
.faq__question:hover {
  background-color: #e7e7e7;
}
.faq__answer {
  padding: 1rem 0 1rem 2rem;
}
.faq__type {
  margin-top: 100px;
}

.toc {
  position: absolute;
  color: #196690;
  font-weight: bold;
}
.toc li {
  list-style-type: none;
  padding: 1rem;
}

.plus-sign {
  font-size: 20px;
  margin-right: 1rem;
  color: #acacac;
}

#__page_favorites {
  padding-top: 20px;
}
#__page_favorites .favorite-items {
  margin-top: 20px;
}
#__page_favorites .favorite-items .favorite-item {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #D7D7D7;
}
#__page_favorites .favorite-items .favorite-item a {
  color: #000;
  text-decoration: none;
}
#__page_favorites .favorite-items .favorite-item button {
  background: none;
  border: none;
  margin-right: 5px;
}
#__page_favorites .favorite-items .favorite-item .splitter {
  padding-left: 5px;
  padding-right: 5px;
}
#__page_favorites .favorite-items .favorite-item .item-info {
  color: #888888;
  margin-bottom: 10px;
}
.__page_publication_schedule table {
  width: 100%;
}
.__page_publication_schedule table td {
  text-align: left;
  padding: 8px 8px 12px;
  border-bottom: 1px dotted lightgray;
}
.__page_publication_schedule table tbody tr:nth-child(odd) {
  background-color: lightgray;
}
.__page_publication_schedule table .title {
  width: 25%;
  font-weight: bold;
}
.__page_publication_schedule table .caption {
  max-width: 40%;
}
.__page_publication_schedule table .current {
  min-width: 100px;
}
.__page_publication_schedule table .next {
  min-width: 100px;
}
.__page_publication_schedule table th {
  text-align: left;
  padding-bottom: 10px;
  border-bottom: 1px solid gray;
}
.__page_publication_schedule aside.note {
  margin-top: 20px;
}
.__page_publication_schedule aside.note p {
  font-size: 14px !important;
}

#__template_single a {
  color: #07547e;
  text-decoration: underline;
}
#__template_single a:hover {
  color: #6c757d;
}
#__template_single .no-format {
  text-decoration: none;
  font-size: inherit;
  color: inherit;
}
#__template_single .centered {
  margin: 0 auto;
}
#__template_single .mastheadWrapper {
  max-width: 100%;
  height: auto;
  padding: 0.5rem;
  margin-bottom: 1rem;
  text-align: center;
}
#__template_single .mastheadWrapper h1 {
  color: white;
}
#__template_single .mastheadWrapper span {
  margin-bottom: -0.5px;
}
#__template_single .navigationWrapper {
  margin-bottom: 20px;
  margin-top: -20px;
}
#__template_single .navigationWrapper a {
  color: #666666;
  font-size: 15px;
}
#__template_single .navigationWrapper a img {
  max-width: 12px;
  width: 100%;
  margin-bottom: 3px;
  height: 12px;
  margin-right: 4px;
}
#__template_single .headerWrapper {
  margin-bottom: 25px;
}
#__template_single .headerWrapper hr.separator {
  width: 100%;
  border-color: #ccc;
  margin-bottom: 10px;
}
@media (max-width: 767.98px) {
  #__template_single .headerWrapper {
    padding-top: 0;
  }
}
#__template_single .headerWrapper .byline {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
#__template_single .headerWrapper .byline-meta {
  display: flex;
  align-items: center;
}
#__template_single .headerWrapper .byline span.bold {
  font-weight: bold;
}
#__template_single .headerWrapper .byline .bold {
  font-weight: bold;
}
#__template_single .headerWrapper .byline .date {
  font-weight: 400;
  color: #666666;
}
#__template_single .headerWrapper .byline .print {
  float: right;
}
#__template_single .headerWrapper .byline .print a {
  color: #1A6690 svg;
  color-color: #666666;
  color-margin-left: 10px;
}
#__template_single .headerWrapper .byline .print:hover {
  cursor: pointer;
}
#__template_single .contentWrapper h2 {
  padding: 1rem 0;
}
#__template_single .contentWrapper p {
  line-height: 32px;
  font-size: 17px;
}
#__template_single .contentWrapper li {
  line-height: 32px;
  font-size: 17px;
  margin-bottom: 1rem;
}
#__template_single .afterContentWrapper hr.separator {
  width: 100%;
  border-color: #ccc;
  margin-bottom: 10px;
}
#__template_single .contentEndWrapper hr.separator {
  width: 100%;
  border-color: #ccc;
}
#__template_single .contentEndWrapper .navigationWrapper {
  margin-top: -14px;
}
#__template_single .contentEndWrapper .navigationWrapper a {
  color: #666666;
  font-size: 15px;
}
#__template_single .pagination {
  border-bottom: 1px solid rgb(193, 193, 193);
  margin-bottom: 20px;
}
#__template_single .pagination .rightSide {
  text-align: right;
}
#__template_single .pagination a {
  color: rgb(148, 148, 148);
}
#__template_single .pagination a:hover, #__template_single .pagination a:visited {
  color: rgb(148, 148, 148);
}
#__template_single .rightSidebar {
  margin: 0;
  padding: 0;
}
#__template_single .rightSidebar .specialReport .specialReportSidebarWrapper {
  text-align: center;
}
#__template_single .rightSidebar .specialReport .specialReportSidebarWrapper a button {
  margin: 0 auto;
  color: white;
  padding: 10px 20px;
  font-size: 1.2em;
  font-weight: 500;
  background: #EEA445;
  border: 0.5px solid #E49025;
  border-radius: 3px;
}
#__template_single .rightSidebar .specialReport .specialReportSidebarWrapper a button:hover, #__template_single .rightSidebar .specialReport .specialReportSidebarWrapper a button:active {
  background: #E49025;
  box-shadow: inset 0 -2px 0 0 #DE810C;
}
#__template_single .rightSidebar .specialReport .specialReportSidebarWrapper a button:focus {
  background: #DE810C;
}
@media (max-width: 1199.98px) {
  #__template_single .rightSidebar {
    max-width: 400px;
    margin: 0 auto;
  }
}
#__template_single .rightSidebar .separatorMini {
  border: 5px solid black;
  display: table;
  width: 70px;
  margin-bottom: 0;
  margin-top: 10px;
}
#__template_single .avatar {
  width: 60px;
  height: 60px;
}
@media (max-width: 767.98px) {
  #__template_single .avatar {
    width: 50px;
    height: 50px;
  }
}

#__template_two_column a {
  color: #07547e;
  text-decoration: underline;
}
#__template_two_column a:hover {
  color: #6c757d;
}
#__template_two_column .centered {
  margin: 0 auto;
}
#__template_two_column .mastheadWrapper {
  max-width: 100%;
  height: auto;
  padding: 0.5rem 3rem;
  margin-bottom: 1rem;
  text-align: center;
}
#__template_two_column .mastheadWrapper h1 {
  color: white;
}
#__template_two_column .mastheadWrapper span {
  margin-bottom: -0.5px;
}
#__template_two_column .navigationWrapper {
  margin-bottom: 20px;
  margin-top: -20px;
}
#__template_two_column .navigationWrapper a {
  color: #666666;
  font-size: 15px;
}
#__template_two_column .navigationWrapper a img {
  max-width: 12px;
  width: 100%;
  margin-bottom: 3px;
  height: 12px;
  margin-right: 4px;
}
#__template_two_column .headerWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1rem;
}
#__template_two_column .headerWrapper .k-button a {
  text-decoration: none;
}
#__template_two_column .headerWrapper hr.separator {
  width: 100%;
  border-color: #ccc;
  margin-bottom: 10px;
}
#__template_two_column .headerWrapper h1 {
  line-height: 2.25rem;
}
@media (max-width: 767.98px) {
  #__template_two_column .headerWrapper {
    padding-top: 1rem;
  }
}
#__template_two_column .headerWrapper .byline-meta {
  display: flex;
  align-items: center;
}
#__template_two_column .headerWrapper .byline span.bold {
  font-weight: bold;
}
#__template_two_column .headerWrapper .byline .bold {
  font-weight: bold;
}
#__template_two_column .headerWrapper .byline .date {
  font-weight: 400;
  color: #666666;
}
#__template_two_column .headerWrapper .byline .print {
  float: right;
}
#__template_two_column .headerWrapper .byline .print a {
  font-size: 17px;
  color: #1A6690;
}
#__template_two_column .headerWrapper .byline .print a svg {
  color: #666666;
  margin-left: 10px;
}
#__template_two_column .headerWrapper .byline .print:hover {
  cursor: pointer;
}
#__template_two_column .contentWrapper h2 {
  padding: 1rem 0;
}
#__template_two_column .contentWrapper p {
  line-height: 32px;
  font-size: 17px;
}
#__template_two_column .contentWrapper li {
  line-height: 32px;
  font-size: 17px;
  margin-bottom: 1rem;
}
#__template_two_column .afterContentWrapper hr.separator {
  width: 100%;
  border-color: #ccc;
  margin-bottom: 10px;
}
#__template_two_column .contentEndWrapper hr.separator {
  width: 100%;
  border-color: #ccc;
}
#__template_two_column .contentEndWrapper .navigationWrapper {
  margin-top: -14px;
}
#__template_two_column .contentEndWrapper .navigationWrapper a {
  color: #666666;
  font-size: 15px;
}
#__template_two_column .pagination {
  border-bottom: 1px solid rgb(193, 193, 193);
  margin-bottom: 20px;
}
#__template_two_column .pagination .rightSide {
  text-align: right;
}
#__template_two_column .pagination a {
  color: rgb(148, 148, 148);
}
#__template_two_column .pagination a:hover, #__template_two_column .pagination a:visited {
  color: rgb(148, 148, 148);
}
#__template_two_column .rightSidebar {
  margin: 0;
  padding: 0;
}
#__template_two_column .rightSidebar .specialReport .specialReportSidebarWrapper {
  text-align: center;
}
#__template_two_column .rightSidebar .specialReport .specialReportSidebarWrapper a button {
  margin: 0 auto;
  color: white;
  padding: 10px 20px;
  font-size: 1.2em;
  font-weight: 500;
  background: #EEA445;
  border: 0.5px solid #E49025;
  border-radius: 3px;
}
#__template_two_column .rightSidebar .specialReport .specialReportSidebarWrapper a button:hover, #__template_two_column .rightSidebar .specialReport .specialReportSidebarWrapper a button:active {
  background: #E49025;
  box-shadow: inset 0 -2px 0 0 #DE810C;
}
#__template_two_column .rightSidebar .specialReport .specialReportSidebarWrapper a button:focus {
  background: #DE810C;
}
@media (max-width: 1199.98px) {
  #__template_two_column .rightSidebar {
    max-width: 400px;
    margin: 0 auto;
  }
}
#__template_two_column .rightSidebar .separatorMini {
  border: 5px solid black;
  display: table;
  width: 70px;
  margin-bottom: 0;
  margin-top: 10px;
}
#__template_two_column .avatar {
  width: 60px;
  height: 60px;
}
@media (max-width: 767.98px) {
  #__template_two_column .avatar {
    width: 50px;
    height: 50px;
  }
}
#__template_two_column .content-styles {
  padding: 0 1rem;
  font-size: 17px;
}
#__template_two_column .twoColumns_container {
  display: flex;
  flex-wrap: nowrap;
  padding: 0;
  min-height: 600px;
}
@media (max-width: 1199.98px) {
  #__template_two_column .twoColumns_container {
    flex-wrap: wrap;
  }
}
#__template_two_column #thirdColumn {
  display: flex;
  flex-direction: column;
}

#__template_archive {
  font-family: "Roboto", sans-serif;
}
#__template_archive .mastheadWrapper {
  max-width: 100%;
  height: auto;
  padding: 15px;
  text-align: center;
}
#__template_archive .mastheadWrapper span {
  font-family: "Roboto Slab", arial, sans-serif;
  font-size: 42px;
  line-height: 60px;
  font-weight: bold;
  color: #ffffff;
}
#__template_archive .headerWrapper {
  padding-top: 25px;
  padding-bottom: 10px;
  margin-bottom: 25px;
  border-bottom: 1px solid #C1C1C1;
}
@media (max-width: 767.98px) {
  #__template_archive .headerWrapper {
    padding-left: 10px;
  }
}
#__template_archive .headerWrapper h1 {
  font-family: "Roboto Slab", sans-serif;
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 28px;
  line-height: 30px;
  font-weight: 700;
}
@media (min-width: 992px) {
  #__template_archive .featuredPost {
    margin-bottom: 40px;
  }
  #__template_archive .featuredPost .postPreview {
    display: flex;
  }
  #__template_archive .featuredPost .postPreview .desc {
    padding-left: 30px;
  }
  #__template_archive .featuredPost .postPreview .desc h2 {
    margin-top: 0;
  }
}
@media (max-width: 1199.98px) {
  #__template_archive .postPreview {
    margin-top: 40px;
  }
}
#__template_archive .loadMoreWrapper .btn_load_more {
  display: block;
  background: none;
  box-shadow: none;
  border: 1px solid #212529;
  margin: 40px auto 0;
  padding: 5px 30px;
}
#__template_archive .loadMoreWrapper .btn_load_more:hover {
  cursor: pointer;
}

#__template_plain {
  padding-top: 40px;
}
#__template_plain > .container .headerWrapper {
  padding-bottom: 20px;
}
#__template_plain > .container .headerWrapper h1 {
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 10px;
}
#__template_plain > .container main h2 {
  font-size: 28px;
}

.postPreview h2 {
  font-size: 24px;
  font-weight: 700;
  margin-top: 20px;
}
.postPreview h2 a {
  color: black;
}
.postPreview h2 a:hover, .postPreview h2 a:visited {
  color: black;
}
.postPreview .thumbnail img {
  width: auto;
  max-width: 100%;
}
.postPreview .byline span.bold {
  font-weight: bold;
}
.postPreview .byline .bold {
  font-weight: bold;
}
.postPreview .byline .date {
  font-weight: 400;
  color: #666666;
}
.postPreview .snippet p {
  font-size: 16px;
  margin-top: 10px;
}

.__widget_subscribe h3 {
  text-align: center;
  font-family: "Roboto Slab", sans-serif;
  font-size: 21px;
  font-weight: bold;
}
.__widget_subscribe img {
  width: auto;
  max-width: 100%;
}
@media (max-width: 1199.98px) {
  .__widget_subscribe img {
    width: 100%;
  }
}
.__widget_subscribe .optin {
  padding: 0 24px 20px;
  color: white;
  margin-bottom: 24px;
  background-color: #07547e;
}
.__widget_subscribe input[type=text] {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  text-align: center;
  line-height: 21px;
  margin: 15px 0 0;
  width: 100%;
  padding: 10px;
  color: #000;
  outline: none;
}
.__widget_subscribe input[type=submit] {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  text-align: center;
  line-height: 21px;
  margin: 15px 0 0;
  width: 100%;
  padding: 10px;
  background-color: #EEA445;
  border: none;
  border-radius: 2px;
}
.__widget_subscribe input[type=submit]:hover {
  background-color: #e49025;
}

.__widget_subscribe_2 {
  margin-top: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.__widget_subscribe_2 h4 {
  font-family: "Roboto Slab", serif;
  font-size: 25px;
  line-height: 25px;
  font-weight: 700;
}
.__widget_subscribe_2 p {
  line-height: 32px;
  margin-bottom: 0;
}
.__widget_subscribe_2 input[type=text] {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  text-align: center;
  line-height: 21px;
  margin: 15px 0 0;
  width: 100%;
  max-width: 500px;
  padding: 10px;
  color: #000;
  outline: none;
  border-top: 1px solid #666666;
  border-left: 1px solid #666666;
  border-bottom: 1px solid #666666;
  border-right: none;
}
@media (max-width: 991.98px) {
  .__widget_subscribe_2 input[type=text] {
    width: 60%;
    max-width: none;
  }
}
.__widget_subscribe_2 input[type=submit] {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  text-align: center;
  line-height: 21px;
  margin: 15px 0 0;
  padding: 10px;
  background-color: #EEA445;
  border: 1px solid #EEA445;
  border-radius: 2px;
}
.__widget_subscribe_2 input[type=submit]:hover {
  background-color: #e49025;
}

.__widget_recommendations h2 {
  margin: 12px 0;
  font-size: 25px;
  font-weight: 700;
  line-height: 31px;
}
.__widget_recommendations .recommendations {
  margin: 0;
  background-color: #f5f5f5;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  padding: 0 20px;
}
.__widget_recommendations .recommendations .recommendation {
  list-style: none;
  padding: 20px 0px 25px;
  border-bottom: 1px solid #b9b9b9;
}
.__widget_recommendations .recommendations .recommendation:last-child {
  border-bottom: none;
}
.__widget_recommendations .recommendations .recommendation a {
  text-decoration: none;
  font-size: 17px;
  font-family: "Roboto", sans-serif;
}
.__widget_recommendations .recommendations .recommendation .date {
  font-size: 14px;
  color: #666666;
}

.sidebar {
  max-width: 300px;
  margin-right: 2rem;
  min-height: 25vh;
  padding-bottom: 20px;
  bottom: 3rem;
  top: 0;
}
@media (max-width: 1199.98px) {
  .sidebar {
    margin-bottom: 1rem;
    min-height: 0;
  }
}
@media (max-width: 1199.98px) {
  .sidebar {
    height: 100%;
    position: inherit;
    top: 0px;
    bottom: 0px;
    z-index: 1;
  }
}
.sidebar hr {
  margin: 2rem 0;
}

.byline {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.byline-meta {
  display: flex;
}

.avatar {
  width: 60px;
  height: 60px;
}
@media (max-width: 767.98px) {
  .avatar {
    width: 50px;
  }
}

.dynamicContent .pbrgAppNotice_container {
  max-width: 300px;
  display: flex;
  align-content: center;
  font-size: 1rem;
  margin: 0 auto;
}
.dynamicContent .pbrgAppNotice_container .mobileAppImg {
  height: 100% !important;
  width: 130px;
  align-items: flex-start;
  flex-direction: row;
}
.dynamicContent .pbrgAppNotice_container-rightSide_icons {
  display: flex;
  margin-top: 10px;
}
.dynamicContent .pbrgAppNotice_container-rightSide_icons > a {
  padding: 0;
}

#masthead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 998;
  display: flex;
  align-items: center;
  max-width: 100%;
  height: 3.4rem;
  margin-bottom: 1rem;
  text-align: center;
}
#masthead h1 {
  color: white;
}
#masthead span {
  margin-bottom: -0.5px;
}
@media (max-width: 767.98px) {
  #masthead {
    position: relative;
    height: 100%;
    margin-bottom: 0;
    padding: 0.5rem 0.2rem;
  }
}
#masthead .mastheadContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 767.98px) {
  #masthead .mastheadContainer {
    flex-direction: column;
  }
}
#masthead .dropdown .dropdownLabel {
  color: #C1C1C1;
  font-size: 12px;
  font-weight: 500;
}
#masthead .dropdown .dropdownBrands {
  color: white;
  font-size: 12px;
  font-weight: 500;
}
#masthead .dropdown .dropdown-menu {
  right: 0;
  left: auto;
  z-index: 998;
  font-size: 12px;
  font-weight: 500;
}
#masthead .dropdown .dropdown-menu a {
  text-decoration: none;
  color: #4E4E4E;
  padding: 0.75rem 2rem;
  font-size: 12px;
  font-weight: 500;
}
#masthead .dropdown .dropdown-menu a:hover {
  color: #9a9a9a;
}
#masthead .dropdown .dropdown-menu a:active {
  background-color: inherit;
}
#masthead .breadcrumb-container {
  font-size: 12px;
  font-weight: 500;
}
#masthead .breadcrumb-container a {
  color: white;
  text-decoration: none;
}
#masthead .breadcrumb-container a:hover {
  text-decoration: underline;
}

@media print {
  .siteContainer > header, .siteContainer > footer {
    display: none !important;
  }
  .mastheadWrapper {
    display: none !important;
  }
  .contentWrapper {
    flex: 1 1 100% !important;
    max-width: 100% !important;
  }
  .navigationWrapper {
    display: none !important;
  }
  span.print {
    display: none !important;
  }
  h1, h2, h3, h4, strong {
    font-family: Georgia, "Times New Roman", Times, serif;
  }
  h1 {
    font-size: 28px !important;
  }
  #__template_single .contentWrapper p {
    font-size: 17pt;
    font-family: Georgia, "Times New Roman", Times, serif;
  }
  #__template_single .contentWrapper p a {
    font-family: Georgia, "Times New Roman", Times, serif;
  }
  #__template_single.__portfolio {
    font-size: 1.1em;
  }
  #__template_single.withPortfolio .postfixPortfolio {
    font-size: 1.1em;
  }
  #__template_two_column .twoColumns_container p {
    font-size: 17pt;
    font-family: Georgia, "Times New Roman", Times, serif;
  }
  #__template_two_column .twoColumns_container p a {
    font-family: Georgia, "Times New Roman", Times, serif;
  }
  #__template_two_column.__portfolio {
    font-size: 1.1em;
  }
  #__template_two_column.withPortfolio .postfixPortfolio {
    font-size: 1.1em;
  }
  .backToTop {
    display: none;
  }
  .headAdContainer {
    display: none;
  }
}
@page {
  size: 8.5in 11in;
  margin: 2cm;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}

html {
  font-size: 88%;
}
@media (max-width: 767.98px) {
  html {
    font-size: 78%;
  }
}

body {
  font-family: "Roboto", sans-serif;
}

h1, h2, h3, h4, h5, h6, p, a {
  color: #000;
}

hr {
  margin-top: 5px;
}

h1 {
  font-family: "Roboto Slab", serif;
  font-size: 1.6rem;
  line-height: 44px;
  font-weight: 800;
}

h2 {
  font-size: 1.3rem;
  font-weight: 700;
}

@media (max-width: 767.98px) {
  h1 {
    font-size: 1.3rem;
    line-height: 2rem;
  }
}
a {
  text-decoration: none;
}
a:visited, a:hover {
  color: #000;
}

hr {
  margin-top: 0.5rem;
}

.video-js a,
.video-js button,
.video-js span {
  font-family: "VideoJS", sans-serif;
}

.btnAsLink {
  background: none;
  border: none;
  color: #337ab7;
}
.btnAsLink:hover {
  text-decoration: underline;
}

.contentWrapper img {
  max-width: 100%;
}

#site-legacyresearch #__page_my_subscriptions > header {
  background: #07547e;
}
@media (max-width: 767px) {
  #site-legacyresearch #__page_my_subscriptions .postPreview .thumbnail {
    display: none;
  }
}
#site-legacyresearch .subscriptionDashboard .subscriber-heading h1 {
  font-family: Roboto Slab, sans-serif;
}
#site-legacyresearch .subscriptionDashboard .subscriber-heading .borderline {
  border-bottom-color: #07547e;
}
#site-legacyresearch .subscriptionDashboard .subscriber-links-list li a {
  border-bottom-color: #07547e;
}
#site-legacyresearch .subscriptionDashboard .pubResourcesWrapper p > a {
  color: #07547e;
  text-decoration: underline;
}
#site-legacyresearch #__template_archive .mastheadWrapper {
  background-color: #07547e;
}
#site-legacyresearch #__template_single .mastheadWrapper {
  background-color: #07547e;
}
#site-legacyresearch #__template_two_column .mastheadWrapper {
  background-color: #07547e;
}
#site-legacyresearch #masthead {
  background-color: #07547e;
}
#site-legacyresearch .loginBtn {
  margin-left: 10px;
}
#site-legacyresearch .loginBtn a {
  color: #07547e;
  font-weight: 800;
  line-height: 27.5px;
  padding: 8px 20px;
  border-color: #07547e;
}
#site-legacyresearch .loginBtn a:hover {
  background-color: #07547e;
  color: white;
}
#site-legacyresearch .hamburger-inner, #site-legacyresearch .hamburger-inner::before, #site-legacyresearch .hamburger-inner::after {
  background-color: #07547e;
}
#site-legacyresearch #lrgDropDownMenu .lrgDropDown {
  min-width: 950px;
  right: 0;
  left: auto;
  z-index: 998;
  font-size: 12px;
  font-weight: 500;
}
#site-legacyresearch #lrgDropDownMenu .lrgDropDown .dropdown-content {
  position: absolute;
  padding: 0 2rem 2rem;
  background-color: #f9f9f9;
  width: 100%;
  left: 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: flex;
  justify-content: center;
}
#site-legacyresearch #lrgDropDownMenu .lrgDropDown .dropdown:hover .dropdown-content {
  display: block;
}
#site-legacyresearch #lrgDropDownMenu .lrgDropDown .dropdown-item-lrg {
  padding: 0.3rem 0.2rem;
}
#site-legacyresearch #lrgDropDownMenu .lrgDropDown .dropdown-item-lrg-selected {
  color: white;
  background: #666;
}
#site-legacyresearch #lrgDropDownMenu .lrgDropDown .dropdown-item-lrg > span {
  color: #666;
}
#site-legacyresearch #lrgDropDownMenu .lrgDropDown .column {
  float: left;
  padding: 1rem 2rem;
}
#site-legacyresearch #lrgDropDownMenu .lrgDropDown .column > h6 {
  font-size: 12px;
  padding-top: 1rem;
}
#site-legacyresearch #lrgDropDownMenu .lrgDropDown .column a {
  float: none;
  text-decoration: none;
  display: block;
  text-align: left;
  padding: 0.5rem 1rem;
}
#site-legacyresearch #lrgDropDownMenu .lrgDropDown .row:after {
  content: "";
  display: table;
  clear: both;
}
#site-legacyresearch #lrgDropDownMenuMobile .lrgDropDown {
  min-width: 240px;
  right: 0;
  left: auto;
  z-index: 998;
  font-size: 12px;
  font-weight: 500;
}
#site-legacyresearch #lrgDropDownMenuMobile .lrgDropDown .dropdown-content {
  position: absolute;
  padding: 0 0rem 2rem;
  background-color: #f9f9f9;
  width: 100%;
  left: 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
#site-legacyresearch #lrgDropDownMenuMobile .lrgDropDown .dropdown:hover .dropdown-content {
  display: block;
}
#site-legacyresearch #lrgDropDownMenuMobile .lrgDropDown .dropdown-item-lrg {
  display: block;
  margin-top: 0.5rem;
  padding: 0.3rem 1rem 0.7rem 2.2rem;
}
#site-legacyresearch #lrgDropDownMenuMobile .lrgDropDown .dropdown-item-lrg-selected {
  display: block;
  color: white;
  background: #666;
  padding: 0.3rem 1.5rem;
}
#site-legacyresearch #lrgDropDownMenuMobile .lrgDropDown .dropdown-item-lrg > span {
  color: #666;
}
#site-legacyresearch #lrgDropDownMenuMobile .lrgDropDown .dropdown-item-lrg > span:hover {
  color: #a0a0a0;
}
#site-legacyresearch #lrgDropDownMenuMobile .lrgDropDown .column {
  float: left;
  padding: 1rem;
  margin-left: 1rem;
}
#site-legacyresearch #lrgDropDownMenuMobile .lrgDropDown .column > h6 {
  font-size: 12px;
  padding-top: 1rem;
}
#site-legacyresearch #lrgDropDownMenuMobile .lrgDropDown .column button {
  padding: 0.7rem;
  float: none;
  text-decoration: none;
  display: block;
  text-align: left;
  cursor: pointer;
  border: none;
  background: none;
}
#site-legacyresearch #lrgDropDownMenuMobile .lrgDropDown .column button:focus {
  outline: none;
}
#site-legacyresearch #lrgDropDownMenuMobile .lrgDropDown .row:after {
  content: "";
  display: table;
  clear: both;
}
#site-legacyresearch #contentNotificationsDropdown a.primary, #site-legacyresearch #contentNotificationsDropdown button.primary {
  color: #07547e;
}
#site-legacyresearch #contentNotificationsDropdown .NotificationEntryWebinar > header {
  background-color: #07547e;
}
#site-legacyresearch #contentNotificationsDropdown .NotificationEntryUpdate .circle,
#site-legacyresearch #contentNotificationsDropdown .NotificationEntryReport .circle,
#site-legacyresearch #contentNotificationsDropdown .NotificationEntryIssue .circle,
#site-legacyresearch #contentNotificationsDropdown .PromotionalNotification .circle {
  background-color: #07547e;
}

#site-palmbeachgroup {
  /* Dashboard */
  /* Publication Page */
  /* Login / Reset */
  /* Header */
  /* Products Page */
  /* Subscription Page */
}
#site-palmbeachgroup > header {
  border-color: #1C6240;
}
@media (max-width: 1199.98px) {
  #site-palmbeachgroup > header .menu li {
    padding-right: 9px;
  }
  #site-palmbeachgroup > header .menu li a, #site-palmbeachgroup > header .menu li button {
    font-size: 1em;
  }
}
#site-palmbeachgroup #__page_my_subscriptions > header {
  background: #1C6240;
}
#site-palmbeachgroup #__page_my_subscriptions .actionWrapper div {
  border: solid 1px #b3b3b3;
  background: #eee;
}
#site-palmbeachgroup #__page_my_subscriptions .actionWrapper div:hover {
  background-color: black;
}
#site-palmbeachgroup #__page_my_subscriptions .actionWrapper div:hover a.visited, #site-palmbeachgroup #__page_my_subscriptions .actionWrapper div:hover a {
  color: white;
}
#site-palmbeachgroup #__page_my_subscriptions .actionWrapper div a {
  color: black;
}
#site-palmbeachgroup #__page_my_subscriptions .actionWrapper div a:hover {
  color: white;
}
#site-palmbeachgroup #__page_my_subscriptions .subscriber-heading {
  border-bottom: 3px solid #1C6240;
}
@media (max-width: 991.98px) {
  #site-palmbeachgroup #__page_my_subscriptions .subscriber-heading {
    text-align: center;
  }
}
@media (max-width: 991.98px) {
  #site-palmbeachgroup #__page_my_subscriptions .subscriber-heading-list {
    justify-content: center;
  }
}
@media (max-width: 767.98px) {
  #site-palmbeachgroup #__page_my_subscriptions .subscriber-heading-list {
    justify-content: center;
    font-size: 0.9rem;
    margin-top: 0;
  }
}
#site-palmbeachgroup #__page_my_subscriptions .otherProducts .otherProduct {
  border-color: #1C6240;
}
#site-palmbeachgroup #__subscription_product .subscriber-heading .borderline {
  border-bottom-color: #1C6240;
}
#site-palmbeachgroup #__subscription_product .subscriber-links-list a {
  border-bottom-color: #1C6240;
}
#site-palmbeachgroup #__page_login .actions, #site-palmbeachgroup #__page_reset .actions {
  border-top-color: #1C6240;
}
#site-palmbeachgroup #__page_login .actions section h4, #site-palmbeachgroup #__page_reset .actions section h4 {
  color: #1C6240;
}
#site-palmbeachgroup #__page_login .actions section button[type=submit], #site-palmbeachgroup #__page_reset .actions section button[type=submit] {
  background-color: #1C6240;
  border-color: #1C6240;
}
#site-palmbeachgroup .myAccountButton {
  background-color: black;
  border-color: black;
}
#site-palmbeachgroup .__page_products .productBox::before {
  border-left-color: #1C6240;
  border-top-color: #1C6240;
}
#site-palmbeachgroup .subscriptionDashboard .actionWrapper div {
  border: solid 1px #b3b3b3;
  background: #eee;
}
#site-palmbeachgroup .subscriptionDashboard .actionWrapper div:hover {
  background-color: black;
}
#site-palmbeachgroup .subscriptionDashboard .actionWrapper div:hover a.visited, #site-palmbeachgroup .subscriptionDashboard .actionWrapper div:hover a {
  color: white;
}
#site-palmbeachgroup .subscriptionDashboard .actionWrapper div a {
  color: black;
}
#site-palmbeachgroup .subscriptionDashboard .actionWrapper div a:hover {
  color: white;
}
#site-palmbeachgroup .subscriptionDashboard .pubResourcesWrapper p > a {
  color: #07547e;
  text-decoration: underline;
}
#site-palmbeachgroup #__template_archive .mastheadWrapper {
  background-color: #1C6240;
}
#site-palmbeachgroup #__template_single .mastheadWrapper {
  background-color: #1C6240;
}
#site-palmbeachgroup #__template_two_column .mastheadWrapper {
  background-color: #1C6240;
}
#site-palmbeachgroup #masthead {
  background-color: #1C6240;
}
#site-palmbeachgroup .skip-link {
  background: #1C6240;
}
#site-palmbeachgroup #contentNotificationsDropdown a.primary, #site-palmbeachgroup #contentNotificationsDropdown button.primary {
  color: #1C6240;
}
#site-palmbeachgroup #contentNotificationsDropdown .NotificationEntryWebinar > header {
  background-color: #1C6240;
}
#site-palmbeachgroup #contentNotificationsDropdown .NotificationEntryUpdate .circle,
#site-palmbeachgroup #contentNotificationsDropdown .NotificationEntryReport .circle,
#site-palmbeachgroup #contentNotificationsDropdown .NotificationEntryIssue .circle,
#site-palmbeachgroup #contentNotificationsDropdown .PromotionalNotification .circle {
  background-color: #1C6240;
}

#site-rogueeconomics { /* Dashboard */
  /* Publication Page */
  /* Login / Reset */
  /* Header */
  /* Products Page */
  /* Subscription Page */
}
#site-rogueeconomics > header {
  border-color: #07547e;
}
@media (max-width: 1199.98px) {
  #site-rogueeconomics > header .menu li {
    padding-right: 9px;
  }
  #site-rogueeconomics > header .menu li a, #site-rogueeconomics > header .menu li button {
    font-size: 1em;
  }
}
#site-rogueeconomics #__page_my_subscriptions > header {
  background: #07547e;
}
#site-rogueeconomics #__page_my_subscriptions .actionWrapper div {
  border: solid 1px #b3b3b3;
  background: #eee;
}
#site-rogueeconomics #__page_my_subscriptions .actionWrapper div:hover {
  background-color: black;
}
#site-rogueeconomics #__page_my_subscriptions .actionWrapper div:hover a.visited, #site-rogueeconomics #__page_my_subscriptions .actionWrapper div:hover a {
  color: white;
}
#site-rogueeconomics #__page_my_subscriptions .actionWrapper div a {
  color: black;
}
#site-rogueeconomics #__page_my_subscriptions .actionWrapper div a:hover {
  color: white;
}
#site-rogueeconomics #__page_my_subscriptions .subscriber-heading {
  border-bottom: 3px solid #07547e;
}
@media (max-width: 991.98px) {
  #site-rogueeconomics #__page_my_subscriptions .subscriber-heading {
    text-align: center;
  }
}
@media (max-width: 991.98px) {
  #site-rogueeconomics #__page_my_subscriptions .subscriber-heading-list {
    justify-content: center;
  }
}
@media (max-width: 767.98px) {
  #site-rogueeconomics #__page_my_subscriptions .subscriber-heading-list {
    justify-content: center;
    font-size: 0.9rem;
    margin-top: 0;
  }
}
#site-rogueeconomics #__page_my_subscriptions .otherProducts .otherProduct {
  border-color: #07547e;
}
#site-rogueeconomics #__subscription_product .subscriber-heading .borderline {
  border-bottom-color: #07547e;
}
#site-rogueeconomics #__subscription_product .subscriber-links-list a {
  border-bottom-color: #07547e;
}
#site-rogueeconomics #__page_login .actions, #site-rogueeconomics #__page_reset .actions {
  border-top-color: #07547e;
}
#site-rogueeconomics #__page_login .actions section h4, #site-rogueeconomics #__page_reset .actions section h4 {
  color: #07547e;
}
#site-rogueeconomics #__page_login .actions section button[type=submit], #site-rogueeconomics #__page_reset .actions section button[type=submit] {
  background-color: #07547e;
  border-color: #07547e;
}
#site-rogueeconomics .myAccountButton {
  background-color: black;
  border-color: black;
}
#site-rogueeconomics .__page_products .productBox::before {
  border-left-color: #07547e;
  border-top-color: #07547e;
}
#site-rogueeconomics .subscriptionDashboard .actionWrapper div {
  border: solid 1px #b3b3b3;
  background: #eee;
}
#site-rogueeconomics .subscriptionDashboard .actionWrapper div:hover {
  background-color: black;
}
#site-rogueeconomics .subscriptionDashboard .actionWrapper div:hover a.visited, #site-rogueeconomics .subscriptionDashboard .actionWrapper div:hover a {
  color: white;
}
#site-rogueeconomics .subscriptionDashboard .actionWrapper div a {
  color: black;
}
#site-rogueeconomics .subscriptionDashboard .actionWrapper div a:hover {
  color: white;
}
#site-rogueeconomics .subscriptionDashboard .pubResourcesWrapper p > a {
  color: #07547e;
  text-decoration: underline;
}
#site-rogueeconomics #__template_archive .mastheadWrapper {
  background-color: #07547e;
}
#site-rogueeconomics #__template_single .mastheadWrapper {
  background-color: #07547e;
}
#site-rogueeconomics #__template_two_column .mastheadWrapper {
  background-color: #07547e;
}
#site-rogueeconomics #masthead {
  background-color: #07547e;
}
#site-rogueeconomics .skip-link {
  background: #07547e;
}
#site-rogueeconomics #contentNotificationsDropdown a.primary, #site-rogueeconomics #contentNotificationsDropdown button.primary {
  color: #07547e;
}
#site-rogueeconomics #contentNotificationsDropdown .NotificationEntryWebinar > header {
  background-color: #07547e;
}
#site-rogueeconomics #contentNotificationsDropdown .NotificationEntryUpdate .circle,
#site-rogueeconomics #contentNotificationsDropdown .NotificationEntryReport .circle,
#site-rogueeconomics #contentNotificationsDropdown .NotificationEntryIssue .circle,
#site-rogueeconomics #contentNotificationsDropdown .PromotionalNotification .circle {
  background-color: #07547e;
}
#site-rogueeconomics .siteLogo {
  max-width: 120px;
  margin: 1rem 0;
}
@media (max-width: 767.98px) {
  #site-rogueeconomics .logo > img {
    max-width: 110px;
  }
}

#site-brownstoneresearch {
  /* Dashboard */
  /* Publication Page */
  /* Login / Reset */
  /* Header */
  /* Products Page */
  /* Subscription Page */
}
#site-brownstoneresearch > header {
  border-color: #8c433c;
}
@media (max-width: 1199.98px) {
  #site-brownstoneresearch > header .menu li {
    padding-right: 9px;
  }
  #site-brownstoneresearch > header .menu li a, #site-brownstoneresearch > header .menu li button {
    font-size: 1em;
  }
}
#site-brownstoneresearch #__page_my_subscriptions > header {
  background: #8c433c;
}
#site-brownstoneresearch #__page_my_subscriptions .actionWrapper div {
  border: solid 1px #b3b3b3;
  background: #eee;
}
#site-brownstoneresearch #__page_my_subscriptions .actionWrapper div:hover {
  background-color: black;
}
#site-brownstoneresearch #__page_my_subscriptions .actionWrapper div:hover a.visited, #site-brownstoneresearch #__page_my_subscriptions .actionWrapper div:hover a {
  color: white;
}
#site-brownstoneresearch #__page_my_subscriptions .actionWrapper div a {
  color: black;
}
#site-brownstoneresearch #__page_my_subscriptions .actionWrapper div a:hover {
  color: white;
}
#site-brownstoneresearch #__page_my_subscriptions .subscriber-heading {
  border-bottom: 3px solid #8c433c;
}
@media (max-width: 991.98px) {
  #site-brownstoneresearch #__page_my_subscriptions .subscriber-heading {
    text-align: center;
  }
}
@media (max-width: 991.98px) {
  #site-brownstoneresearch #__page_my_subscriptions .subscriber-heading-list {
    justify-content: center;
  }
}
@media (max-width: 767.98px) {
  #site-brownstoneresearch #__page_my_subscriptions .subscriber-heading-list {
    justify-content: center;
    font-size: 0.9rem;
    margin-top: 0;
  }
}
#site-brownstoneresearch #__page_my_subscriptions .otherProducts .otherProduct {
  border-color: #8c433c;
}
#site-brownstoneresearch #__subscription_product .subscriber-heading .borderline {
  border-bottom-color: #8c433c;
}
#site-brownstoneresearch #__subscription_product .subscriber-links-list a {
  border-bottom-color: #8c433c;
}
#site-brownstoneresearch #__page_login .actions, #site-brownstoneresearch #__page_reset .actions {
  border-top-color: #8c433c;
}
#site-brownstoneresearch #__page_login .actions section h4, #site-brownstoneresearch #__page_reset .actions section h4 {
  color: #8c433c;
}
#site-brownstoneresearch #__page_login .actions section button[type=submit], #site-brownstoneresearch #__page_reset .actions section button[type=submit] {
  background-color: #8c433c;
  border-color: #8c433c;
}
#site-brownstoneresearch .myAccountButton {
  background-color: black;
  border-color: black;
}
#site-brownstoneresearch .__page_products .productBox::before {
  border-left-color: #8c433c;
  border-top-color: #8c433c;
}
#site-brownstoneresearch .subscriptionDashboard .actionWrapper div {
  border: solid 1px #b3b3b3;
  background: #eee;
}
#site-brownstoneresearch .subscriptionDashboard .actionWrapper div:hover {
  background-color: black;
}
#site-brownstoneresearch .subscriptionDashboard .actionWrapper div:hover a.visited, #site-brownstoneresearch .subscriptionDashboard .actionWrapper div:hover a {
  color: white;
}
#site-brownstoneresearch .subscriptionDashboard .actionWrapper div a {
  color: black;
}
#site-brownstoneresearch .subscriptionDashboard .actionWrapper div a:hover {
  color: white;
}
#site-brownstoneresearch .subscriptionDashboard .pubResourcesWrapper p > a {
  color: #07547e;
  text-decoration: underline;
}
#site-brownstoneresearch #__template_archive .mastheadWrapper {
  background-color: #8c433c;
}
#site-brownstoneresearch #__template_single .mastheadWrapper {
  background-color: #8c433c;
}
#site-brownstoneresearch #__template_two_column .mastheadWrapper {
  background-color: #8c433c;
}
#site-brownstoneresearch #masthead {
  background-color: #8c433c;
}
#site-brownstoneresearch .skip-link {
  background: #8c433c;
}
#site-brownstoneresearch #contentNotificationsDropdown a.primary, #site-brownstoneresearch #contentNotificationsDropdown button.primary {
  color: #8c433c;
}
#site-brownstoneresearch #contentNotificationsDropdown .NotificationEntryWebinar > header {
  background-color: #8c433c;
}
#site-brownstoneresearch #contentNotificationsDropdown .NotificationEntryUpdate .circle,
#site-brownstoneresearch #contentNotificationsDropdown .NotificationEntryReport .circle,
#site-brownstoneresearch #contentNotificationsDropdown .NotificationEntryIssue .circle,
#site-brownstoneresearch #contentNotificationsDropdown .PromotionalNotification .circle {
  background-color: #8c433c;
}
#site-brownstoneresearch .siteLogo {
  max-width: 200px;
  margin: 1rem 0;
}
#site-brownstoneresearch .logo img {
  max-width: 270px;
  width: 300px;
}
@media (max-width: 767.98px) {
  #site-brownstoneresearch .logo img {
    max-width: 160px;
  }
}

#site-jeffclarktrader {
  /* Dashboard */
  /* Publication Page */
  /* Login / Reset */
  /* Header */
  /* Products Page */
  /* Subscription Page */
}
#site-jeffclarktrader > header {
  border-color: #004761;
}
@media (max-width: 1199.98px) {
  #site-jeffclarktrader > header .menu li {
    padding-right: 9px;
  }
  #site-jeffclarktrader > header .menu li a, #site-jeffclarktrader > header .menu li button {
    font-size: 1em;
  }
}
#site-jeffclarktrader #__page_my_subscriptions > header {
  background: #004761;
}
#site-jeffclarktrader #__page_my_subscriptions .actionWrapper div {
  border: solid 1px #b3b3b3;
  background: #eee;
}
#site-jeffclarktrader #__page_my_subscriptions .actionWrapper div:hover {
  background-color: black;
}
#site-jeffclarktrader #__page_my_subscriptions .actionWrapper div:hover a.visited, #site-jeffclarktrader #__page_my_subscriptions .actionWrapper div:hover a {
  color: white;
}
#site-jeffclarktrader #__page_my_subscriptions .actionWrapper div a {
  color: black;
}
#site-jeffclarktrader #__page_my_subscriptions .actionWrapper div a:hover {
  color: white;
}
#site-jeffclarktrader #__page_my_subscriptions .subscriber-heading {
  border-bottom: 3px solid #004761;
}
@media (max-width: 991.98px) {
  #site-jeffclarktrader #__page_my_subscriptions .subscriber-heading {
    text-align: center;
  }
}
@media (max-width: 991.98px) {
  #site-jeffclarktrader #__page_my_subscriptions .subscriber-heading-list {
    justify-content: center;
  }
}
@media (max-width: 767.98px) {
  #site-jeffclarktrader #__page_my_subscriptions .subscriber-heading-list {
    justify-content: center;
    font-size: 0.9rem;
    margin-top: 0;
  }
}
#site-jeffclarktrader #__page_my_subscriptions .otherProducts .otherProduct {
  border-color: #004761;
}
#site-jeffclarktrader #__subscription_product .subscriber-heading .borderline {
  border-bottom-color: #004761;
}
#site-jeffclarktrader #__subscription_product .subscriber-links-list a {
  border-bottom-color: #004761;
}
#site-jeffclarktrader #__page_login .actions, #site-jeffclarktrader #__page_reset .actions {
  border-top-color: #004761;
}
#site-jeffclarktrader #__page_login .actions section h4, #site-jeffclarktrader #__page_reset .actions section h4 {
  color: #004761;
}
#site-jeffclarktrader #__page_login .actions section button[type=submit], #site-jeffclarktrader #__page_reset .actions section button[type=submit] {
  background-color: #004761;
  border-color: #004761;
}
#site-jeffclarktrader .myAccountButton {
  background-color: black;
  border-color: black;
}
#site-jeffclarktrader .__page_products .productBox::before {
  border-left-color: #004761;
  border-top-color: #004761;
}
#site-jeffclarktrader .subscriptionDashboard .actionWrapper div {
  border: solid 1px #b3b3b3;
  background: #eee;
}
#site-jeffclarktrader .subscriptionDashboard .actionWrapper div:hover {
  background-color: black;
}
#site-jeffclarktrader .subscriptionDashboard .actionWrapper div:hover a.visited, #site-jeffclarktrader .subscriptionDashboard .actionWrapper div:hover a {
  color: white;
}
#site-jeffclarktrader .subscriptionDashboard .actionWrapper div a {
  color: black;
}
#site-jeffclarktrader .subscriptionDashboard .actionWrapper div a:hover {
  color: white;
}
#site-jeffclarktrader .subscriptionDashboard .pubResourcesWrapper p > a {
  color: #07547e;
  text-decoration: underline;
}
#site-jeffclarktrader #__template_archive .mastheadWrapper {
  background-color: #004761;
}
#site-jeffclarktrader #__template_single .mastheadWrapper {
  background-color: #004761;
}
#site-jeffclarktrader #__template_two_column .mastheadWrapper {
  background-color: #004761;
}
#site-jeffclarktrader #masthead {
  background-color: #004761;
}
#site-jeffclarktrader .skip-link {
  background: #004761;
}
#site-jeffclarktrader #contentNotificationsDropdown a.primary, #site-jeffclarktrader #contentNotificationsDropdown button.primary {
  color: #004761;
}
#site-jeffclarktrader #contentNotificationsDropdown .NotificationEntryWebinar > header {
  background-color: #004761;
}
#site-jeffclarktrader #contentNotificationsDropdown .NotificationEntryUpdate .circle,
#site-jeffclarktrader #contentNotificationsDropdown .NotificationEntryReport .circle,
#site-jeffclarktrader #contentNotificationsDropdown .NotificationEntryIssue .circle,
#site-jeffclarktrader #contentNotificationsDropdown .PromotionalNotification .circle {
  background-color: #004761;
}

#site-opportunistictrader {
  /* Dashboard */
  /* Publication Page */
  /* Login / Reset */
  /* Header */
  /* Products Page */
  /* Subscription Page */
}
#site-opportunistictrader > header {
  border-color: #06163a;
}
@media (max-width: 1199.98px) {
  #site-opportunistictrader > header .menu li {
    padding-right: 9px;
  }
  #site-opportunistictrader > header .menu li a, #site-opportunistictrader > header .menu li button {
    font-size: 1em;
  }
}
#site-opportunistictrader #__page_my_subscriptions > header {
  background: #06163a;
}
#site-opportunistictrader #__page_my_subscriptions .actionWrapper div {
  border: solid 1px #b3b3b3;
  background: #eee;
}
#site-opportunistictrader #__page_my_subscriptions .actionWrapper div:hover {
  background-color: black;
}
#site-opportunistictrader #__page_my_subscriptions .actionWrapper div:hover a.visited, #site-opportunistictrader #__page_my_subscriptions .actionWrapper div:hover a {
  color: white;
}
#site-opportunistictrader #__page_my_subscriptions .actionWrapper div a {
  color: black;
}
#site-opportunistictrader #__page_my_subscriptions .actionWrapper div a:hover {
  color: white;
}
#site-opportunistictrader #__page_my_subscriptions .subscriber-heading {
  border-bottom: 3px solid #06163a;
}
@media (max-width: 991.98px) {
  #site-opportunistictrader #__page_my_subscriptions .subscriber-heading {
    text-align: center;
  }
}
@media (max-width: 991.98px) {
  #site-opportunistictrader #__page_my_subscriptions .subscriber-heading-list {
    justify-content: center;
  }
}
@media (max-width: 767.98px) {
  #site-opportunistictrader #__page_my_subscriptions .subscriber-heading-list {
    justify-content: center;
    font-size: 0.9rem;
    margin-top: 0;
  }
}
#site-opportunistictrader #__page_my_subscriptions .otherProducts .otherProduct {
  border-color: #06163a;
}
#site-opportunistictrader #__subscription_product .subscriber-heading .borderline {
  border-bottom-color: #06163a;
}
#site-opportunistictrader #__subscription_product .subscriber-links-list a {
  border-bottom-color: #06163a;
}
#site-opportunistictrader #__page_login .actions, #site-opportunistictrader #__page_reset .actions {
  border-top-color: #06163a;
}
#site-opportunistictrader #__page_login .actions section h4, #site-opportunistictrader #__page_reset .actions section h4 {
  color: #06163a;
}
#site-opportunistictrader #__page_login .actions section button[type=submit], #site-opportunistictrader #__page_reset .actions section button[type=submit] {
  background-color: #06163a;
  border-color: #06163a;
}
#site-opportunistictrader .myAccountButton {
  background-color: black;
  border-color: black;
}
#site-opportunistictrader .__page_products .productBox::before {
  border-left-color: #06163a;
  border-top-color: #06163a;
}
#site-opportunistictrader .subscriptionDashboard .actionWrapper div {
  border: solid 1px #b3b3b3;
  background: #eee;
}
#site-opportunistictrader .subscriptionDashboard .actionWrapper div:hover {
  background-color: black;
}
#site-opportunistictrader .subscriptionDashboard .actionWrapper div:hover a.visited, #site-opportunistictrader .subscriptionDashboard .actionWrapper div:hover a {
  color: white;
}
#site-opportunistictrader .subscriptionDashboard .actionWrapper div a {
  color: black;
}
#site-opportunistictrader .subscriptionDashboard .actionWrapper div a:hover {
  color: white;
}
#site-opportunistictrader .subscriptionDashboard .pubResourcesWrapper p > a {
  color: #07547e;
  text-decoration: underline;
}
#site-opportunistictrader #__template_archive .mastheadWrapper {
  background-color: #06163a;
}
#site-opportunistictrader #__template_single .mastheadWrapper {
  background-color: #06163a;
}
#site-opportunistictrader #__template_two_column .mastheadWrapper {
  background-color: #06163a;
}
#site-opportunistictrader #masthead {
  background-color: #06163a;
}
#site-opportunistictrader .skip-link {
  background: #06163a;
}
#site-opportunistictrader #contentNotificationsDropdown a.primary, #site-opportunistictrader #contentNotificationsDropdown button.primary {
  color: #06163a;
}
#site-opportunistictrader #contentNotificationsDropdown .NotificationEntryWebinar > header {
  background-color: #06163a;
}
#site-opportunistictrader #contentNotificationsDropdown .NotificationEntryUpdate .circle,
#site-opportunistictrader #contentNotificationsDropdown .NotificationEntryReport .circle,
#site-opportunistictrader #contentNotificationsDropdown .NotificationEntryIssue .circle,
#site-opportunistictrader #contentNotificationsDropdown .PromotionalNotification .circle {
  background-color: #06163a;
}
#site-opportunistictrader .copyright {
  padding-top: 45px;
}
@media (max-width: 991.98px) {
  #site-opportunistictrader .copyright {
    padding-top: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  #site-opportunistictrader .footer-top-contact {
    margin-bottom: 16px;
  }
}
@media (min-width: 768px) {
  #site-opportunistictrader .telephoneByline {
    margin-top: 0;
  }
}
#site-opportunistictrader .siteLogo {
  max-width: 220px;
}

#site-newparadigmresearch {
  /* Dashboard */
  /* Publication Page */
  /* Login / Reset */
  /* Header */
  /* Products Page */
  /* Subscription Page */
}
#site-newparadigmresearch > header {
  border-color: #142a4f;
}
@media (max-width: 1199.98px) {
  #site-newparadigmresearch > header .menu li {
    padding-right: 9px;
  }
  #site-newparadigmresearch > header .menu li a, #site-newparadigmresearch > header .menu li button {
    font-size: 1em;
  }
}
#site-newparadigmresearch #__page_my_subscriptions > header {
  background: #142a4f;
}
#site-newparadigmresearch #__page_my_subscriptions .actionWrapper div {
  border: solid 1px #b3b3b3;
  background: #eee;
}
#site-newparadigmresearch #__page_my_subscriptions .actionWrapper div:hover {
  background-color: black;
}
#site-newparadigmresearch #__page_my_subscriptions .actionWrapper div:hover a.visited, #site-newparadigmresearch #__page_my_subscriptions .actionWrapper div:hover a {
  color: white;
}
#site-newparadigmresearch #__page_my_subscriptions .actionWrapper div a {
  color: black;
}
#site-newparadigmresearch #__page_my_subscriptions .actionWrapper div a:hover {
  color: white;
}
#site-newparadigmresearch #__page_my_subscriptions .subscriber-heading {
  border-bottom: 3px solid #142a4f;
}
@media (max-width: 991.98px) {
  #site-newparadigmresearch #__page_my_subscriptions .subscriber-heading {
    text-align: center;
  }
}
@media (max-width: 991.98px) {
  #site-newparadigmresearch #__page_my_subscriptions .subscriber-heading-list {
    justify-content: center;
  }
}
@media (max-width: 767.98px) {
  #site-newparadigmresearch #__page_my_subscriptions .subscriber-heading-list {
    justify-content: center;
    font-size: 0.9rem;
    margin-top: 0;
  }
}
#site-newparadigmresearch #__page_my_subscriptions .otherProducts .otherProduct {
  border-color: #142a4f;
}
#site-newparadigmresearch #__subscription_product .subscriber-heading .borderline {
  border-bottom-color: #142a4f;
}
#site-newparadigmresearch #__subscription_product .subscriber-links-list a {
  border-bottom-color: #142a4f;
}
#site-newparadigmresearch #__page_login .actions, #site-newparadigmresearch #__page_reset .actions {
  border-top-color: #142a4f;
}
#site-newparadigmresearch #__page_login .actions section h4, #site-newparadigmresearch #__page_reset .actions section h4 {
  color: #142a4f;
}
#site-newparadigmresearch #__page_login .actions section button[type=submit], #site-newparadigmresearch #__page_reset .actions section button[type=submit] {
  background-color: #142a4f;
  border-color: #142a4f;
}
#site-newparadigmresearch .myAccountButton {
  background-color: black;
  border-color: black;
}
#site-newparadigmresearch .__page_products .productBox::before {
  border-left-color: #142a4f;
  border-top-color: #142a4f;
}
#site-newparadigmresearch .subscriptionDashboard .actionWrapper div {
  border: solid 1px #b3b3b3;
  background: #eee;
}
#site-newparadigmresearch .subscriptionDashboard .actionWrapper div:hover {
  background-color: black;
}
#site-newparadigmresearch .subscriptionDashboard .actionWrapper div:hover a.visited, #site-newparadigmresearch .subscriptionDashboard .actionWrapper div:hover a {
  color: white;
}
#site-newparadigmresearch .subscriptionDashboard .actionWrapper div a {
  color: black;
}
#site-newparadigmresearch .subscriptionDashboard .actionWrapper div a:hover {
  color: white;
}
#site-newparadigmresearch .subscriptionDashboard .pubResourcesWrapper p > a {
  color: #07547e;
  text-decoration: underline;
}
#site-newparadigmresearch #__template_archive .mastheadWrapper {
  background-color: #142a4f;
}
#site-newparadigmresearch #__template_single .mastheadWrapper {
  background-color: #142a4f;
}
#site-newparadigmresearch #__template_two_column .mastheadWrapper {
  background-color: #142a4f;
}
#site-newparadigmresearch #masthead {
  background-color: #142a4f;
}
#site-newparadigmresearch .skip-link {
  background: #142a4f;
}
#site-newparadigmresearch #contentNotificationsDropdown a.primary, #site-newparadigmresearch #contentNotificationsDropdown button.primary {
  color: #142a4f;
}
#site-newparadigmresearch #contentNotificationsDropdown .NotificationEntryWebinar > header {
  background-color: #142a4f;
}
#site-newparadigmresearch #contentNotificationsDropdown .NotificationEntryUpdate .circle,
#site-newparadigmresearch #contentNotificationsDropdown .NotificationEntryReport .circle,
#site-newparadigmresearch #contentNotificationsDropdown .NotificationEntryIssue .circle,
#site-newparadigmresearch #contentNotificationsDropdown .PromotionalNotification .circle {
  background-color: #142a4f;
}
#site-newparadigmresearch .siteLogo {
  max-width: 170px;
  margin: 1rem 0;
}
#site-newparadigmresearch .logo > img {
  max-width: 270px;
  width: 240px;
}
@media (max-width: 767.98px) {
  #site-newparadigmresearch .logo > img {
    max-width: 110px;
  }
}
@media (max-width: 991.98px) {
  #site-newparadigmresearch .logo > img {
    max-width: 160px;
  }
}

#site-widemoatresearch {
  /* Dashboard */
  /* Publication Page */
  /* Login / Reset */
  /* Header */
  /* Products Page */
  /* Subscription Page */
}
#site-widemoatresearch > header {
  border-color: #0058A3;
}
@media (max-width: 1199.98px) {
  #site-widemoatresearch > header .menu li {
    padding-right: 9px;
  }
  #site-widemoatresearch > header .menu li a, #site-widemoatresearch > header .menu li button {
    font-size: 1em;
  }
}
#site-widemoatresearch #__page_my_subscriptions > header {
  background: #0058A3;
}
#site-widemoatresearch #__page_my_subscriptions .actionWrapper div {
  border: solid 1px #b3b3b3;
  background: #eee;
}
#site-widemoatresearch #__page_my_subscriptions .actionWrapper div:hover {
  background-color: black;
}
#site-widemoatresearch #__page_my_subscriptions .actionWrapper div:hover a.visited, #site-widemoatresearch #__page_my_subscriptions .actionWrapper div:hover a {
  color: white;
}
#site-widemoatresearch #__page_my_subscriptions .actionWrapper div a {
  color: black;
}
#site-widemoatresearch #__page_my_subscriptions .actionWrapper div a:hover {
  color: white;
}
#site-widemoatresearch #__page_my_subscriptions .subscriber-heading {
  border-bottom: 3px solid #0058A3;
}
@media (max-width: 991.98px) {
  #site-widemoatresearch #__page_my_subscriptions .subscriber-heading {
    text-align: center;
  }
}
@media (max-width: 991.98px) {
  #site-widemoatresearch #__page_my_subscriptions .subscriber-heading-list {
    justify-content: center;
  }
}
@media (max-width: 767.98px) {
  #site-widemoatresearch #__page_my_subscriptions .subscriber-heading-list {
    justify-content: center;
    font-size: 0.9rem;
    margin-top: 0;
  }
}
#site-widemoatresearch #__page_my_subscriptions .otherProducts .otherProduct {
  border-color: #0058A3;
}
#site-widemoatresearch #__subscription_product .subscriber-heading .borderline {
  border-bottom-color: #0058A3;
}
#site-widemoatresearch #__subscription_product .subscriber-links-list a {
  border-bottom-color: #0058A3;
}
#site-widemoatresearch #__page_login .actions, #site-widemoatresearch #__page_reset .actions {
  border-top-color: #0058A3;
}
#site-widemoatresearch #__page_login .actions section h4, #site-widemoatresearch #__page_reset .actions section h4 {
  color: #0058A3;
}
#site-widemoatresearch #__page_login .actions section button[type=submit], #site-widemoatresearch #__page_reset .actions section button[type=submit] {
  background-color: #0058A3;
  border-color: #0058A3;
}
#site-widemoatresearch .myAccountButton {
  background-color: black;
  border-color: black;
}
#site-widemoatresearch .__page_products .productBox::before {
  border-left-color: #0058A3;
  border-top-color: #0058A3;
}
#site-widemoatresearch .subscriptionDashboard .actionWrapper div {
  border: solid 1px #b3b3b3;
  background: #eee;
}
#site-widemoatresearch .subscriptionDashboard .actionWrapper div:hover {
  background-color: black;
}
#site-widemoatresearch .subscriptionDashboard .actionWrapper div:hover a.visited, #site-widemoatresearch .subscriptionDashboard .actionWrapper div:hover a {
  color: white;
}
#site-widemoatresearch .subscriptionDashboard .actionWrapper div a {
  color: black;
}
#site-widemoatresearch .subscriptionDashboard .actionWrapper div a:hover {
  color: white;
}
#site-widemoatresearch .subscriptionDashboard .pubResourcesWrapper p > a {
  color: #07547e;
  text-decoration: underline;
}
#site-widemoatresearch #__template_archive .mastheadWrapper {
  background-color: #0058A3;
}
#site-widemoatresearch #__template_single .mastheadWrapper {
  background-color: #0058A3;
}
#site-widemoatresearch #__template_two_column .mastheadWrapper {
  background-color: #0058A3;
}
#site-widemoatresearch #masthead {
  background-color: #0058A3;
}
#site-widemoatresearch .skip-link {
  background: #0058A3;
}
#site-widemoatresearch #contentNotificationsDropdown a.primary, #site-widemoatresearch #contentNotificationsDropdown button.primary {
  color: #0058A3;
}
#site-widemoatresearch #contentNotificationsDropdown .NotificationEntryWebinar > header {
  background-color: #0058A3;
}
#site-widemoatresearch #contentNotificationsDropdown .NotificationEntryUpdate .circle,
#site-widemoatresearch #contentNotificationsDropdown .NotificationEntryReport .circle,
#site-widemoatresearch #contentNotificationsDropdown .NotificationEntryIssue .circle,
#site-widemoatresearch #contentNotificationsDropdown .PromotionalNotification .circle {
  background-color: #0058A3;
}
#site-widemoatresearch .siteLogo {
  max-width: 170px;
  margin: 1rem 0;
}
#site-widemoatresearch .logo > img {
  max-width: 170px;
  width: 170px;
}
@media (max-width: 767.98px) {
  #site-widemoatresearch .logo > img {
    max-width: 110px;
  }
}
@media (max-width: 991.98px) {
  #site-widemoatresearch .logo > img {
    max-width: 160px;
  }
}

#site-gulfportanalytics {
  /* Dashboard */
  /* Publication Page */
  /* Login / Reset */
  /* Header */
  /* Products Page */
  /* Subscription Page */
}
#site-gulfportanalytics > header {
  border-color: #0274C1;
}
@media (max-width: 1199.98px) {
  #site-gulfportanalytics > header .menu li {
    padding-right: 9px;
  }
  #site-gulfportanalytics > header .menu li a, #site-gulfportanalytics > header .menu li button {
    font-size: 1em;
  }
}
#site-gulfportanalytics #__page_my_subscriptions > header {
  background: #0274C1;
}
#site-gulfportanalytics #__page_my_subscriptions .actionWrapper div {
  border: solid 1px #b3b3b3;
  background: #eee;
}
#site-gulfportanalytics #__page_my_subscriptions .actionWrapper div:hover {
  background-color: black;
}
#site-gulfportanalytics #__page_my_subscriptions .actionWrapper div:hover a.visited, #site-gulfportanalytics #__page_my_subscriptions .actionWrapper div:hover a {
  color: white;
}
#site-gulfportanalytics #__page_my_subscriptions .actionWrapper div a {
  color: black;
}
#site-gulfportanalytics #__page_my_subscriptions .actionWrapper div a:hover {
  color: white;
}
#site-gulfportanalytics #__page_my_subscriptions .subscriber-heading {
  border-bottom: 3px solid #0274C1;
}
@media (max-width: 991.98px) {
  #site-gulfportanalytics #__page_my_subscriptions .subscriber-heading {
    text-align: center;
  }
}
@media (max-width: 991.98px) {
  #site-gulfportanalytics #__page_my_subscriptions .subscriber-heading-list {
    justify-content: center;
  }
}
@media (max-width: 767.98px) {
  #site-gulfportanalytics #__page_my_subscriptions .subscriber-heading-list {
    justify-content: center;
    font-size: 0.9rem;
    margin-top: 0;
  }
}
#site-gulfportanalytics #__page_my_subscriptions .otherProducts .otherProduct {
  border-color: #0274C1;
}
#site-gulfportanalytics #__subscription_product .subscriber-heading .borderline {
  border-bottom-color: #0274C1;
}
#site-gulfportanalytics #__subscription_product .subscriber-links-list a {
  border-bottom-color: #0274C1;
}
#site-gulfportanalytics #__page_login .actions, #site-gulfportanalytics #__page_reset .actions {
  border-top-color: #0274C1;
}
#site-gulfportanalytics #__page_login .actions section h4, #site-gulfportanalytics #__page_reset .actions section h4 {
  color: #0274C1;
}
#site-gulfportanalytics #__page_login .actions section button[type=submit], #site-gulfportanalytics #__page_reset .actions section button[type=submit] {
  background-color: #0274C1;
  border-color: #0274C1;
}
#site-gulfportanalytics .myAccountButton {
  background-color: black;
  border-color: black;
}
#site-gulfportanalytics .__page_products .productBox::before {
  border-left-color: #0274C1;
  border-top-color: #0274C1;
}
#site-gulfportanalytics .subscriptionDashboard .actionWrapper div {
  border: solid 1px #b3b3b3;
  background: #eee;
}
#site-gulfportanalytics .subscriptionDashboard .actionWrapper div:hover {
  background-color: black;
}
#site-gulfportanalytics .subscriptionDashboard .actionWrapper div:hover a.visited, #site-gulfportanalytics .subscriptionDashboard .actionWrapper div:hover a {
  color: white;
}
#site-gulfportanalytics .subscriptionDashboard .actionWrapper div a {
  color: black;
}
#site-gulfportanalytics .subscriptionDashboard .actionWrapper div a:hover {
  color: white;
}
#site-gulfportanalytics .subscriptionDashboard .pubResourcesWrapper p > a {
  color: #07547e;
  text-decoration: underline;
}
#site-gulfportanalytics #__template_archive .mastheadWrapper {
  background-color: #0274C1;
}
#site-gulfportanalytics #__template_single .mastheadWrapper {
  background-color: #0274C1;
}
#site-gulfportanalytics #__template_two_column .mastheadWrapper {
  background-color: #0274C1;
}
#site-gulfportanalytics #masthead {
  background-color: #0274C1;
}
#site-gulfportanalytics .skip-link {
  background: #0274C1;
}
#site-gulfportanalytics #contentNotificationsDropdown a.primary, #site-gulfportanalytics #contentNotificationsDropdown button.primary {
  color: #0274C1;
}
#site-gulfportanalytics #contentNotificationsDropdown .NotificationEntryWebinar > header {
  background-color: #0274C1;
}
#site-gulfportanalytics #contentNotificationsDropdown .NotificationEntryUpdate .circle,
#site-gulfportanalytics #contentNotificationsDropdown .NotificationEntryReport .circle,
#site-gulfportanalytics #contentNotificationsDropdown .NotificationEntryIssue .circle,
#site-gulfportanalytics #contentNotificationsDropdown .PromotionalNotification .circle {
  background-color: #0274C1;
}
#site-gulfportanalytics .logo img {
  max-width: 220px;
  width: 220px;
}
@media (max-width: 767.98px) {
  #site-gulfportanalytics .logo img {
    max-width: 160px;
  }
}
#site-gulfportanalytics .accordion-item.focused {
  background-color: #0274C1;
}
#site-gulfportanalytics .course-container .course-header .course-name .site-name a {
  color: #0274C1;
}
#site-gulfportanalytics .course-container .course-selector-utility > header {
  background-color: #0274C1;
}

@media (min-width: 576px) {
  .container {
    max-width: initial !important;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 970px !important;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px !important;
  }
}
#outerContainer {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
#outerContainer .siteContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
#outerContainer .siteContainer > header,
#outerContainer .siteContainer > main,
#outerContainer .siteContainer > footer {
  transition: all 0.15s ease;
}
#outerContainer .siteContainer > header.slideActive,
#outerContainer .siteContainer > main.slideActive,
#outerContainer .siteContainer > footer.slideActive {
  position: relative;
}
#outerContainer .siteContainer > main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

/* For page transitions */
.fade-enter {
  opacity: 1;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

/* While DOM is loading */
#root:not(:empty) + .sk-cube-grid {
  display: none;
}

.sk-cube-grid {
  width: 40px;
  height: 40px;
  margin: 100px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #333;
  float: left;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
  animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
  animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
  animation-delay: 0.2s;
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    transform: scale3D(1, 1, 1);
  }
  35% {
    transform: scale3D(0, 0, 1);
  }
}
/* Toastify */
.Toastify__toast--success {
  background-color: #28a745;
  border-left: 8px solid #1f8838;
  color: white;
}
.Toastify__toast--success .Toastify__toast-body {
  padding: 8px 15px;
}

/* Rc-Tooltip */
.rc-tooltip, .rc-tooltip-content, .rc-tooltip-arrow, .rc-tooltip-inner {
  background-color: #6c757d;
  box-shadow: none;
}

/* k-button replacement */
.k-button {
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  font-size: 1rem;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  position: relative;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  border-color: #e4e7eb;
  color: #212529;
  background-color: #e4e7eb;
}

/*# sourceMappingURL=App.css.map */

